import { FunctionComponent, useState, useEffect } from 'react';


import './SortArrows.scss';


interface IProps {
    sort:string;
    reverse:boolean;
    currentSort:string;
}

const SortArrows:FunctionComponent<IProps> = props => {
    return (
        <div className={"sort-arrows " + (props.sort === props.currentSort ? 'selected ' : '') + (props.reverse ? 'reverse' : '') }></div>
    );
}

export default SortArrows;