import axios from 'axios';
import gsap from 'gsap';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

import { AppContext } from '../../AppContext';

import './ExpandingCircle.scss';
interface IProps {
    points:number;
    progress:number[];
    elementColors:{color:string}[];
    isSlotted:number[];
    selectedPoint:number|undefined;
    cameraPosition:{x:number, y:number}|undefined;
    setPoints(points:{x:number, y:number}[]):void;
    setFigureSize(width:number):void;
}

const ExpandingCircle = (props:IProps) => {
    const appContext = useContext(AppContext);
    const circleRef = React.useRef<HTMLDivElement>(null);
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const requestIdRef = useRef<number|null>(null);
    const ballRef = useRef(props.progress);
    const cameraRef = useRef(props.cameraPosition);
    const isSlottedRef = useRef(props.isSlotted);
    const elemenetColorsRef = useRef(props.elementColors); 
    const selectedSlot = useRef<number>(props.selectedPoint != undefined ? props.selectedPoint : -1);
    const selectionRotationRef = useRef(0);
    const [points, _setPoints] = React.useState<{x:number, y:number}[]>([]);
    //const [percent, setPercent] = React.useState<number>(0);
    const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(
        null
      );
    const size = useWindowSize();
    // const updateBall = () => {
    //   const ball = ballRef.current;
    //   ball.percent = ball.percent + .1;
    //   if (ball.percent > 100) {
    //       ball.percent = 0;
    //   }
    //   //console.log(ball);
    // };
  

    const renderFrame = () => {
        if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx && context) {
      let parentWidth = circleRef.current?.clientWidth || 0
      let parentHeight = circleRef.current?.clientHeight || 0
          let lineWidth = 5;
            canvasRef.current.width = parentWidth;
            canvasRef.current.height = parentHeight;

            

      // let xCenter = parentWidth / 2;
      // let yCenter = parentWidth / 2;

      // if (!cameraRef.current) {
      //   cameraRef.current = {x: 0, y: 0};
      // }

      //updateBall();
      //console.log(size);
     
      //this.clearRect(0, 0, size.width, size.height);

      //
      
      
      // ctx.setTransform(1,0,0,1,0,0);//reset the transform matrix as it is cumulative
      ctx.clearRect(0, 0, parentWidth, parentHeight);
      //ctx.translate(cameraRef.current.x,cameraRef.current.y - yCenter);


    //   var camX = -cameraRef.current.x + parentWidth/2;
    // var camY = -cameraRef.current.y + parentHeight/2;
    
    
    // let offsetX = parentWidth /2 - cameraRef.current.x; 
    // let offsetY = parentHeight /2 - cameraRef.current.y; 
   
    //ctx.translate(offsetX, offsetY); 


      ctx.save();
      let circleDiameter = parentHeight * .6;
      if (parentWidth <= parentHeight) {
        circleDiameter = parentWidth * .6;
      }
      
      let figureSize = (circleDiameter / (props.points / 1.5))
      if(props.points <= 4) {
         figureSize = (circleDiameter / props.points)
      }
      props.setFigureSize(figureSize);
  let circle = new Path2D();  // <<< Declaration
  circle.arc(parentWidth  / 2 , parentHeight / 2, (circleDiameter - lineWidth) /2 , 0, 2 * Math.PI, false);
  
  ctx.beginPath();
 

  ctx.clip(circle);
  


ctx.lineWidth = lineWidth * 2;
ctx.strokeStyle = 'rgba(255, 255, 255, 0.07)';
ctx.stroke(circle);  // <<< pass circle here too


// the outline
// context.lineWidth = lineWidth;
// context.strokeStyle = '#FFF';
// context.stroke();


context.closePath();

let pointArray:{x:number,y:number}[] = [];
  
let buffer = (lineWidth * 2);

let cx = parentWidth /2;
let cy = parentHeight /2;

if (props.points == 3) {
    pointArray = pointArray.concat(drawStar((parentWidth /2),(parentHeight /2), 3, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, 1));
}
if (props.points == 4) {
    
            ctx.translate(cx, cy);
            ctx.rotate(Math.PI * 1);
            ctx.translate(-cx, -cy);
            drawStar((parentWidth /2),(parentHeight /2), 4, (circleDiameter - buffer)/2,circleDiameter /6, context, lineWidth, undefined);

            ctx.translate(cx, cy);
            ctx.rotate(Math.PI * -1);
            ctx.translate(-cx, -cy);


    //drawStar((parentWidth /2),(parentHeight /2), 4, (circleDiameter)/3,circleDiameter /6, context, lineWidth, 0.25);
    pointArray = pointArray.concat(drawSquare((parentWidth /2),(parentHeight /2), (circleDiameter - buffer), context, lineWidth, 1));
    
}
if (props.points == 5) {
    pointArray = pointArray.concat(drawStar((parentWidth /2),(parentHeight /2), 5, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, 1));
}

if (props.points == 6) {
    let pointArray1 = drawStar((parentWidth /2),(parentHeight /2), 3, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, undefined);

    let pointArray2 = drawStar((parentWidth /2),(parentHeight /2), 3, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, 1);

    pointArray = [pointArray2[0], pointArray1[2], pointArray2[1], pointArray1[0], pointArray2[2], pointArray1[1]];
}
if (props.points == 7) {
    pointArray = pointArray.concat(drawStar((parentWidth /2),(parentHeight /2), 7, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, 1));
}
if (props.points == 8) {
    // let pointArray1 = pointArray.concat(drawStar((parentWidth /2),(parentHeight /2), 2, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, undefined));
    // let pointArray2 = pointArray.concat(drawStar((parentWidth /2),(parentHeight /2), 2, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, 0.25));
    
    
    // pointArray = [pointArray2[3],pointArray1[0],pointArray1[1],pointArray1[2],pointArray1[3],pointArray2[0],pointArray2[1],pointArray2[2]];
    pointArray = pointArray.concat(drawStar((parentWidth /2),(parentHeight /2), 4, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, 1));
    pointArray = [pointArray[0], pointArray[5], pointArray[2], pointArray[7], pointArray[4], pointArray[1],pointArray[6], pointArray[3]];
}
if (props.points == 9) {
    pointArray = pointArray.concat(drawStar((parentWidth /2),(parentHeight /2), 9, (circleDiameter - buffer)/2,-(circleDiameter - buffer)/2, context, lineWidth, 1));
    
}

let drawRef = ballRef.current;



    // ctx.translate(cx, cy);
    // ctx.rotate(Math.PI * 1);
    // ctx.translate(-cx, -cy);
    let offset = 0;
    
    var deg = 360 / pointArray.length;

    


    for (let i = 0; i < pointArray.length; i++) {
        //console.log(drawRef.percent);
        ctx.translate(cx, cy);
          ctx.rotate(Math.PI);
          ctx.translate(-cx, -cy);
        if (i === selectedSlot.current) {
          selectionRotationRef.current -= 0.2;
          drawSelection(cx, cy, cx, ctx, pointArray[i].x, pointArray[i].y, selectionRotationRef.current);
        }
        
        
        ctx.translate(cx, cy);
            ctx.rotate(Math.PI);
            ctx.translate(-cx, -cy);
        
        
        drawPoint(cx, cy, (circleDiameter / 2) * (1 - drawRef[i]), (-90 - (i * deg) - offset), -1, 'A', ctx);
        //console.log(props.selectedPoint);
        //if(i + 1 === ((props.selectedPoint || 99) - 99)) {
          //console.log(i);
          
        //}
        
    }

    props.setPoints(pointArray);


    ctx.restore();

    for (let i = 0; i < pointArray.length; i++) {
      
      // ctx.translate(cx, cy);
      //   ctx.rotate(Math.PI);
      //   ctx.translate(-cx, -cy);
      
      let powerRadius = circleDiameter;
      
      
      if (elemenetColorsRef.current[i]) {
       // ctx.globalCompositeOperation = "luminosity";
        drawElementalPower(pointArray[i].x, pointArray[i].y, powerRadius,  ctx, elemenetColorsRef.current[i].color);
        //ctx.globalCompositeOperation = "source-over";
        drawPointCircles(pointArray[i].x, pointArray[i].y, figureSize, isSlottedRef.current[i], ctx);
        
      } 
      
      drawPointCircles(pointArray[i].x, pointArray[i].y, figureSize / 4, 1 - isSlottedRef.current[i], ctx);
      // ctx.translate(cx, cy);
      //     ctx.rotate(Math.PI);
      //     ctx.translate(-cx, -cy);
      
  }

  
    
  
  
 // drawCircle(ball.x, ball.y, ball.radius, "#444");


}
        
if (ctx) {
  setContext(ctx);
}
        }
    };
  
    const tick = () => {
      if (!canvasRef.current) return;
      
      renderFrame();
      requestIdRef.current = requestAnimationFrame(tick);
    };
  
    useEffect(() => {
      requestIdRef.current = requestAnimationFrame(tick);
      return () => {
        cancelAnimationFrame(requestIdRef.current || 0);
      };
    }, [context, size.width, size.height]);
  
    function getFraction(n:number):number {
        return  n - Math.floor(n);
      }
      
      useEffect(() => {
        gsap.to(ballRef.current, {
          duration: 6,
          ease: "power3.out",
          endArray: props.progress
        });
        gsap.to(cameraRef.current || {x:0, y:0}, {
          duration: 6,
          ease: "power3.out",
          x: props.cameraPosition?.x || 0,
          y: props.cameraPosition?.y || 0,
        });

        gsap.to(isSlottedRef.current, {
          duration: 2,
          ease: "power3.out",
          endArray: props.isSlotted
        });

        for (let i = 0; i < elemenetColorsRef.current.length; i++) {
          gsap.to(elemenetColorsRef.current[i], {
            duration: 3,
            ease: "power3.out",
            color: props.elementColors[i].color,
            
          });
        }
        


      }, [props.progress, props.elementColors, props.cameraPosition, props.isSlotted]);

      // useEffect(() => {
      //   gsap.to(cameraRef.current || {x:0, y:0}, {
      //     duration: 6,
      //     ease: "power3.out",
      //     x: props.cameraPosition?.x || 0,
      //     y: props.cameraPosition?.y || 0,
      //     onUpdate: () => console.log(cameraRef.current)
      //   });
      // }, [props.cameraPosition]);


      useEffect(() => {
        selectedSlot.current = props.selectedPoint != undefined ? props.selectedPoint : -1;
        //console.log(selectedSlot.current);
      }, [props.selectedPoint]);

      function drawPointCircles(x:number, y:number, figureSize:number, progress:number, ctx:any) {
        ctx.beginPath();
        ctx.arc(x, y, (figureSize * .65) * progress, 0, 2 * Math.PI, false);
            ctx.lineWidth = 4;
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.07)';
            ctx.stroke();  

            ctx.beginPath();
            ctx.arc(x, y, (figureSize * .8) * progress, 0, 2 * Math.PI, false);
            ctx.lineWidth = 4;
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.07)';
            ctx.stroke();  
      }

    function drawElementalPower(x:number, y:number, radius:number, ctx:any, color:string) {

      var gradient = ctx.createRadialGradient(x, y, 0, x, y, radius);
gradient.addColorStop(0, color);
gradient.addColorStop(1, color.replace(/[^,]+(?=\))/, '0'));
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = gradient;
      ctx.fill();
          // ctx.lineWidth = 2;
          // ctx.strokeStyle = 'blue';
          // ctx.stroke();  

//           let circle = new Path2D();  // <<< Declaration
//           circle.arc(x , y, radius , 0, 2 * Math.PI, false);
//           ctx.beginPath();

//           var gradient = ctx.createRadialGradient(x, y, 0, x + radius, y + radius, 100);
 
// // our two color stops
// gradient.addColorStop(0, "#FFCC00");
// gradient.addColorStop(1, "#B4CB02");
 
// // assigning the gradient
// ctx.fillStyle = 'blue';
// ctx.fill();




//           ctx.lineWidth = 2;
//           ctx.strokeStyle = 'blue';
//           ctx.stroke(circle);  


         // var gradient = ctx.createRadialGradient(110,90,30, 100,100,70);
    }

    function drawPoint(cx:number, cy:number, radius:number, angle:number,distance:number,label:string, ctx:any):void {
        var x = cx + radius * Math.cos(-angle*Math.PI/180) * distance;
        var y = cy + radius * Math.sin(-angle*Math.PI/180) * distance;
      
        

        
        //    ctx.translate(x, y);
        //   ctx.rotate(angle*Math.PI);
        //      ctx.translate(-x, -y);
        ctx.beginPath();
        let rotatedPoints = rotatePoints(x, y, x - (cx ), y, angle + 90)
        ctx.moveTo(rotatedPoints[0],rotatedPoints[1]);

      // End point (180,47)
      let rotatedPointsEnd = rotatePoints(x, y, x + (cx ), y, angle + 90)
      ctx.lineTo(rotatedPointsEnd[0],rotatedPointsEnd[1]);
      ctx.lineWidth = 5;
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.05)';
      //   }
       ctx.stroke();
        
      
      }

      function drawSelection(cx:number, cy:number, outerRadius:number, ctx:any, x:number, y:number, rotation:number ){
        let lines = 40;
        let angle = 360 / lines;
        
        //
          // ctx.translate(x, y);
          // ctx.rotate((Math.PI / 180) * angle);
          // ctx.translate(-x, -y);
          
          
          // let circle = new Path2D();  // <<< Declaration
          // circle.arc(x , y, cx / 3 , 0, 2 * Math.PI, false);
          // ctx.beginPath();

          // ctx.lineWidth = 2;
          // ctx.strokeStyle = 'blue';
          // ctx.stroke(circle);  
          
          
          for (let i = 0; i <= lines; i++) {

          ctx.beginPath();
          ctx.moveTo(x, y);
          
          let rotatedPoints = rotatePoints(x, y, x, y + cx / 5, (angle * i) + rotation);
          ctx.lineTo(rotatedPoints[0],rotatedPoints[1]);
          ctx.closePath();
          ctx.lineWidth = 2;
          ctx.strokeStyle = 'rgba(255, 255, 255, 0.05)';
          ctx.stroke();
          
          }
          
        //}
        
        //var x = cx + radius * Math.cos(-angle*Math.PI/180) * distance;
        //var y = cy + radius * Math.sin(-angle*Math.PI/180) * distance;
      
        //    ctx.translate(x, y);
        //   ctx.rotate(angle*Math.PI);
        //      ctx.translate(-x, -y);
        // ctx.beginPath();
        // let rotatedPoints = rotatePoints(x, y, x - (cx ), y, angle + 90)
        // ctx.moveTo(rotatedPoints[0],rotatedPoints[1]);
      
      // End point (180,47)
      // let rotatedPointsEnd = rotatePoints(x, y, x + (cx ), y, angle + 90)
      // ctx.lineTo(rotatedPointsEnd[0],rotatedPointsEnd[1]);
      // ctx.lineWidth = 5;
      // ctx.strokeStyle = 'rgba(255, 255, 255, 0.05)';
      //   }
       //ctx.stroke();
        
      
      }

      function drawStar(cx:number,cy:number,spikes:number,outerRadius:number,innerRadius:number, ctx:any, lineWidth:number, rotate:number|undefined):{x:number, y:number}[] {
        
        let pointArray:{x:number,y:number}[] = [];
        
      
        var rot=Math.PI/2*3;
        var x=cx;
        var y=cy;
        var step=Math.PI/spikes;
        var rotationDeg = (360 * (rotate  || 1) )/2;
        if (rotate) {
            ctx.translate(cx, cy);
            ctx.rotate(Math.PI * rotate);
            ctx.translate(-cx, -cy);
        }
        ctx.beginPath();
        ctx.moveTo(cx,cy-outerRadius)
        for(let i=0;i<spikes;i++){
          x=cx+Math.cos(rot)*outerRadius;
          y=cy+Math.sin(rot)*outerRadius;
          if(rotate) {
            let rotatedPoints = rotatePoints(cx, cy, x, y, rotationDeg);
            //setPoint(rotatedPoints[0], rotatedPoints[1]);
      
            pointArray.push({x: rotatedPoints[0], y:rotatedPoints[1]});
          } else {
              //setPoint(x,y);
              pointArray.push({x, y});
          }
          ctx.lineTo(x,y)
          rot+=step
      
          x=cx+Math.cos(rot)*innerRadius;
          y=cy+Math.sin(rot)*innerRadius;
          ctx.lineTo(x,y)
          if (spikes == 2 || spikes == 4) {
            if(rotate) {
                let rotatedPoints = rotatePoints(cx, cy, x, y, rotationDeg);
                pointArray.push({x: rotatedPoints[0], y:rotatedPoints[1]});
              } else {
                  //setPoint(x,y);
                  pointArray.push({x, y});
              }
          }
      
          rot+=step
        }
        ctx.lineTo(cx,cy-outerRadius);
        ctx.closePath();
        ctx.lineWidth=lineWidth;
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.07)';
        //ctx.strokeStyle='blue';
        ctx.stroke();
        
        //ctx.fillStyle='skyblue';
        //ctx.fill();
        //_setPoints(pointArray);
      return pointArray;
      //     for (let i = 0; i < pointArray.length; i++) {
      //         let point = pointArray[i];
      //         ctx.beginPath(); 
      //   // Staring point (10,45)
      //   ctx.moveTo(0,point.y);
      //   // End point (180,47)
      //   ctx.lineTo(outerRadius,point.y);
      //   // Make the line visible
      //   ctx.strokeStyle = '#FFF';
      //   ctx.stroke();
      //     }
      }

      function drawSquare(cx:number,cy:number,width:number, ctx:any, lineWidth:number, rotate:number|undefined):{x:number, y:number}[] {
        
        let pointArray:{x:number,y:number}[] = [];
        

      
        var x=cx;
        var y=cy;
        //var rotationDeg = (360 * (rotate  || 1) )/2;
        if (rotate) {
            ctx.translate(cx, cy);
            ctx.rotate(Math.PI * rotate);
            ctx.translate(-cx, -cy);
        }
        ctx.beginPath();
        let half = (width/2);
        ctx.moveTo(cx, cy - half);
        ctx.lineTo(cx + half, cy);
        ctx.lineTo(cx, cy + half);
        ctx.lineTo(cx - half, cy);
        ctx.lineTo(cx, cy - half);
        
        pointArray.push({x : cx + half, y : cy});
        
        pointArray.push({x : cx, y : cy - half});
        pointArray.push({x : cx - half, y : cy});
        pointArray.push({x : cx, y : cy + half});
       
        pointArray.reverse();

        ctx.closePath();
        ctx.lineWidth=lineWidth;
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.07)';
        ctx.stroke();
        

      return pointArray;

      }
      
      function setPoint(x:number, y:number):void {
        let pointArray = points;
        pointArray.push({x, y});
        _setPoints([...pointArray]);
      }
      
      function rotatePoints(cx:number, cy:number, x:number, y:number, angle:number) {
        var radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
            ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        return [nx, ny];
      }

     
      
    return (
        <div className="expanding-circle" ref={circleRef}>
           <canvas
           data-paper-resize
            id="canvas"
            ref={canvasRef}
            width="100%"
            height="100%"
          ></canvas>
          {props.selectedPoint}
        </div>
      )
    
  }
  
  export default ExpandingCircle;
  
  
// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: 0 ,
      height: 0,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        if (window && window.innerWidth && window.innerHeight) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
        }
        
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  