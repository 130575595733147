import axios from 'axios';
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import { AppContext } from '../../AppContext';
import FigureDetail from '../../interfaces/FigureDetail';
import RewardToken from '../../interfaces/RewardToken';
import Token from '../../interfaces/Token';
import Figure from '../Figure';
import './FigureDisplay.scss';
import VisibilitySensor from 'react-visibility-sensor';
import { useInView } from 'react-intersection-observer';

interface IProps {
    token?:RewardToken|FigureDetail|Token;
    imageKey?:string;
    size:string;
    isVisible?:boolean;
}



const FigureDisplay = (props:IProps) => {
  const imageKey = props.token?.imageKey || props.imageKey

    // const { ref, inView, entry } = useInView({
    //     threshold: 0,
    //     triggerOnce: true
    //   });
  
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

  return (
    
    <div className="figure-display">
    {imageKey && 
      <div className={'figure-display-image-wrapper ' + (imageLoaded ? 'loaded' : '')}>
          {(props.isVisible === undefined || props.isVisible == true) && <img draggable="false" onLoad={() => setImageLoaded(true)} src={`https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/${imageKey}/token${props.size}`} />}
        </div>}
    </div>
    
  )
}

export default FigureDisplay;