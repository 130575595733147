import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import {Link, useParams, Outlet} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import FigureDetail from '../../interfaces/FigureDetail';
import './ExperimentPage.scss';

declare var window: any;

interface IProps {
  
}

const ExperimentPage = (props:IProps) => {


//   const [checked, setChecked] = useState(false);

//   var currentSong = 0;
//   function playNextSong() {
    
    
//     if(currentSong >= songs.length) {
//       currentSong = 0;
//     }
//     document.removeEventListener('ended', playNextSong);
//     songs[currentSong].load();
//     songs[currentSong].play();

//     songs[currentSong].addEventListener('ended', playNextSong);
//     currentSong++;

//   }
//   var songs:HTMLAudioElement[] = [];
//   songs[0] = new Audio(song2);
//   songs[0].src = "../../audio/The-Gold-Crow-Slow.mp3";
//   songs[0].crossOrigin = 'anonymous';
//   songs[0].volume = 0.05;
//   songs[1] = new Audio(song3);
//   songs[1].src ="../../audio/The-Circle-is-Drawn.mp3";
//   songs[1].crossOrigin = 'anonymous';
//   songs[1].volume = 0.05;
//   songs[2] = new Audio(song4);
//   songs[2].src ="../../audio/Ghosts-of-the-Past.mp3";
//   songs[2].crossOrigin = 'anonymous';
//   songs[2].volume = 0.05;
//   function playSound() {

//    // console.log(checked);
  
//     const audioCtx =  new (window.AudioContext || window.webkitAudioContext)({ latencyHint: 'playback' })

//     const gainNode = audioCtx.createGain();
// gainNode.gain.value = 0.1; // setting it to 10%
// gainNode.connect(audioCtx.destination);



// var source = audioCtx.createBufferSource();
//          fetch("/audio/Drone-96.mp3").then(resp=>resp.arrayBuffer().then(function(data) {
//          //  console.log(data);
//           // audioCtx.decodeAudioData(data));
//           audioCtx.decodeAudioData(data, function(buffer:any) {
//               source.buffer = buffer;
//               source.connect(gainNode);
//               source.loop = true;
//               source.start(0);
//           });
//          }));
         
//     playNextSong();

// }

// function stopSound() {

//     const AudioContext = window.AudioContext || window.webkitAudioContext;
//     const audioCtx = new AudioContext();
  
//     var som = new Audio(song);
//     som.src = "./audio/Drone.wav";
//     som.crossOrigin = 'anonymous';
  
//     var som2 = new Audio(song2);
//     som2.src = "./audio/Theme.wav";
//     som2.crossOrigin = 'anonymous';
//     som.load();
//         som2.load();
  
//     console.log(som.src);

//     som.pause();
//     som2.pause();
//     // setPlaying();
//   // }

// }





// useEffect(() => {
//   const setFromClick = () => setChecked(true);
//   window.addEventListener("click", setFromClick);
//   return () => {
//     window.removeEventListener("click", setFromClick);
//   };
// }, []);

// useEffect(() => {

//   if(checked) {
//     playSound();
//   }
//   const setFromClick = () => setChecked(true);

//   window.removeEventListener("click", setFromClick);

// }, [checked]);





  const context = React.useContext(AppContext);
  
  return (
    <div className="experiment-page fade-in">
      <div className='experiment-nav'><ExperimentNav /></div>
      <div className='experiment-page-wrapper'>
      <Outlet />
      </div> 
    </div>
  )
}

export default ExperimentPage;

