import React from 'react';
import { useState, useEffect } from 'react';

interface IProps {
    initialSeconds:number;
    onTimerEnd():void;
}

const LongTimer = (props:IProps) => {

    const [secondsLeft, setSecondsLeft] = React.useState(props.initialSeconds);
  
    const days = Math.floor(secondsLeft/24/60/60);
    const hoursLeft = Math.floor((secondsLeft) - (days*86400));
    const hours = Math.floor(hoursLeft/3600);
    const minutesLeft = Math.floor((hoursLeft) - (hours*3600));
    const minutes = Math.floor(minutesLeft/60);
    const seconds = secondsLeft % 60;

    React.useEffect(() => {
      const interval = setInterval(() => {
        if (secondsLeft == 0) {
          clearInterval(interval);
          props.onTimerEnd();
        } else {
          setSecondsLeft(secondsLeft - 1)
        }
      }, 1000)
      return () => clearInterval(interval)
    }, [secondsLeft])

    return (
        <div className="long-timer">
            {hours > 0 && <span>{hours < 10 ? "0" + hours : hours}:</span>}            
            <span>{minutes < 10 && hours > 0 ? "0" + minutes : minutes}</span>:            
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>
        </div>
    )
}

export default LongTimer;