import axios from 'axios';

import { useState, useEffect, RefObject, useContext } from 'react';
import { AppContext } from '../../AppContext';
import Balances from '../../interfaces/Balances';
import Balance from '../Balance';

import './Vial.scss';

interface IProps {

  balances: Balances;
}


const Vial = (props: IProps) => {

  const balances = props.balances;

  function getTotal(): number {
    return balances.death + balances.life + balances.air + balances.fire + balances.memory + balances.vision + balances.earth + balances.water;
  }

  const context = useContext(AppContext);
  //const board = context.board?.slice(0,100) || [];
  const total = getTotal();


  function getPercentage(part: number): number {
    return (100 * part) / total;
  }


  return (
    <div className='vial-wrapper'>
      {props.balances &&
        <div className='vial'>


          <div className='contents-mask'>
            <div className='balance-flask'>
              <div style={{ width: getPercentage(balances.death) + '%' }} className='vial-1'></div>
              <div style={{ width: getPercentage(balances.life) + '%' }} className='vial-2'></div>
              <div style={{ width: getPercentage(balances.fire) + '%' }} className='vial-3'></div>
              <div style={{ width: getPercentage(balances.water) + '%' }} className='vial-4'></div>
              <div style={{ width: getPercentage(balances.earth) + '%' }} className='vial-5'></div>
              <div style={{ width: getPercentage(balances.air) + '%' }} className='vial-6'></div>
              <div style={{ width: getPercentage(balances.memory) + '%' }} className='vial-7'></div>
              <div style={{ width: getPercentage(balances.vision) + '%' }} className='vial-8'></div>
            </div>
          </div>
          <div className='vial-overlay'>
            <span className='vial-left'></span>
            <span className='vial-center'></span>
            <span className='vial-right'></span>
          </div>
        </div>}
      {/* <div className='flask-label'>
        <div style={{ width: getPercentage(balances.death) + '%' }} className='vial-1-label'>{balances.death}</div>
        <div style={{ width: getPercentage(balances.life) + '%' }} className='vial-2-label'>{balances.life}</div>
        <div style={{ width: getPercentage(balances.fire) + '%' }} className='vial-3-label'>{balances.fire}</div>
        <div style={{ width: getPercentage(balances.water) + '%' }} className='vial-4-label'>{balances.water}</div>
        <div style={{ width: getPercentage(balances.earth) + '%' }} className='vial-5-label'>{balances.earth}</div>
        <div style={{ width: getPercentage(balances.air) + '%' }} className='vial-6-label'>{balances.air}</div>
        <div style={{ width: getPercentage(balances.memory) + '%' }} className='vial-7-label'>{balances.memory}</div>
        <div style={{ width: getPercentage(balances.vision) + '%' }} className='vial-8-label'>{balances.vision}</div>
      </div> */}
      {/* <Balance balances={props.balances} /> */}
    </div>
  )
}

export default Vial;
