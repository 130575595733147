import axios from 'axios';
import React from 'react'
import { useState, useEffect } from 'react';




const Countdown = (props:any) => {
    
  const [secondsLeft, setSecondsLeft] = React.useState(props.secondsLeft);
  
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft == 0) {
        clearInterval(interval);
        //console.log('Times up!')
        props.setEpoch();
      } else {
        setSecondsLeft(secondsLeft - 1)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [secondsLeft])
  
  const days = Math.floor(secondsLeft/24/60/60);
  const hoursLeft = Math.floor((secondsLeft) - (days*86400));
  const hours = Math.floor(hoursLeft/3600);
  const minutesLeft = Math.floor((hoursLeft) - (hours*3600));
  const minutes = Math.floor(minutesLeft/60);
  const seconds = secondsLeft % 60;
    
  return (
    <div className="clock">
    
    <div className="panel">
      <small>DAYS</small>
      <span>{days < 10 ? "0" + days : days}</span>
    </div>
    <div className="panel">
      <small>HOURS</small>
      <span>{hours < 10 ? "0" + hours : hours}</span>
    </div>
    <div className="panel">
      <small>MINUTES</small>
      <span>{minutes < 10 ? "0" + minutes : minutes}</span>
    </div>
    <div className="panel">
      <small>SECONDS</small>
      <span>{seconds < 10 ? "0" + seconds : seconds}</span>
    </div>
      
    </div>
  )
}

export default Countdown;