import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import TaskBoard from '../../../../interfaces/TaskBoard';
import TaskBoardSlot from '../../../../interfaces/TaskBoardSlot';
import Figure from '../../../Figure';
import FigureDisplay from '../../../FigureDisplay';
import './CompletedSet.scss';


interface IProps {
    taskBoard:TaskBoard;
}

const CompletedSet = (props:IProps) => {

  const context = useContext(AppContext);
  const session = context.userSession;
  
  const taskBoard = props.taskBoard;
  
  function allSameFigure():boolean {
       let figure:number|undefined;
       let isAllSame = true;
       for (let i = 0; i < taskBoard.slots.length; i++) {
         if(i == 0) {
           figure = taskBoard.slots[i].token.figureId;
         } else {
           if (taskBoard.slots[i].token.figureId != figure) {
             isAllSame = false;
           }
         }

       }
       return isAllSame;
  }
return (
    <div className='completed-set'>
      
      {allSameFigure() && <div className='special'>
      <svg style={{width: '24px', height: '24px'}} viewBox="0 0 24 24">
      <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
      </svg>
      </div>}
      <div className='completed-set-display'>
                                {taskBoard.slots.map((slot:TaskBoardSlot) => 
                                    <div key={'slot-' + slot.id}>
                                        

                                            {slot.token && <FigureDisplay token={slot.token} size={'eighth'} />}
                                       
                                    </div>
                                )}
              </div>
            <div className='completed-set-users'>
            {taskBoard.slots.map((slot:TaskBoardSlot, index:number) => 
                                    <span key={'user-' + slot.id} className={'' + (context.auth && slot.token.userId == session?.user.id ? 'is-user' : '')}>
                                        
                                            
                                        {index == taskBoard.slots.length -1 ? 'and ' : ''}{slot.token.userName}{index < taskBoard.slots.length -1 ? ', ' : ''}
                                    </span>
                                )}
              </div>             
    </div>
  )
}

export default CompletedSet;