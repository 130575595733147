import axios from 'axios';
import React, { Fragment } from 'react'

import { useParams, NavLink } from "react-router-dom";
import { AppContext } from '../../AppContext';
import FigureDetail from '../../components/FigureDetail';
import FigureDisplay from '../../components/FigureDisplay';
import RewardTier from '../../interfaces/RewardTier';
import RewardToken from '../../interfaces/RewardToken';
import Task from '../../interfaces/Task';
import TaskReward from '../../interfaces/TaskReward';
import User from '../../interfaces/User';
import './TaskRewardsPage.scss';

interface IProps {

}

const TaskRewardsPage = (props: IProps) => {

  const context = React.useContext(AppContext);
  const { taskAlias } = useParams();
  const user: User = context.user || {} as User;
  const [task, setTask] = React.useState<Task>();
  const [taskRewards, setTaskRewards] = React.useState<TaskReward[]>([]);
  const [userRewards, setUserRewards] = React.useState<RewardToken[]>([]);
  const [selectedTaskReward, setSelectedTaskReward] = React.useState<number>(0);


  React.useEffect(() => {

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${taskAlias}`;
    //context.setShowLoader?.('Loading Task', true);
    axios({
      "method": "GET",
      "url": url
    })
      .then((response) => {
        let task: Task = response.data;
        
        setTask(task);
        //context.setShowLoader?.('Loading Tasks', false);
      })
      .catch((error) => {
        console.log(error);
        //context.setShowLoader?.('Loading Tasks', false);
      })

  }, []);



  React.useEffect(() => {

    if (task && task.id) {
      let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${task.id}/rewards`;
      context.setShowLoader?.('Loading Rewards', true);
      axios({
        "method": "GET",
        "url": url
      })
        .then((response) => {
          let taskReward:TaskReward[] = response.data;
          //console.log(taskReward);
          //let updatedPages = pages;
          //updatedPages.push(rankPage);
          setTaskRewards(taskReward);
          context.setShowLoader?.('Loading Rewards', false);
        })
        .catch((error) => {
          console.log(error);
          context.setShowLoader?.('Loading Rewards', false);
        });

        if (user.id) {
          let userRewardsUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${task.id}/rewards/user/${user.id}`;
          //context.setShowLoader?.('Loading Rewards', true);
          axios({
            "method": "GET",
            "url": userRewardsUrl
          })
            .then((response) => {
              let rewardTokens:RewardToken[] = response.data;
              
              //let updatedPages = pages;
              //updatedPages.push(rankPage);
              setUserRewards(rewardTokens);
              //context.setShowLoader?.('Loading Rewards', false);
            })
            .catch((error) => {
              console.log(error);
              //context.setShowLoader?.('Loading Rewards', false);
            });
        }
        
    }



  }, [task?.id]);

  function userHasReward(tokenId:number):boolean {
    let tokenReward = userRewards.filter((token:RewardToken) => token.id === tokenId)[0];
    if (tokenReward) {
      return true;
    }
    return false;
  }






 
  return (
    <div className="task-rewards-page fade-in">
      {task && task.type === 1 &&
        <>
      <h1>{task.name}</h1>
      <div>
      <div className='task-menu'>
            <ul>
                <li><NavLink to={`../world`} end>World</NavLink></li>
                <li><NavLink to={`../world/${task.alias}/rankings`} end>Rankings</NavLink></li>
                <li><NavLink to={`../world/${task.alias}/rewards`} end>Rewards</NavLink></li>
                <li><NavLink to={`../world/${task.alias}/history`} end>History</NavLink></li>
            </ul>
        </div>
      </div>
      <div className='task-rewards-page-content'>
        <h2>Rewards</h2>
        
        <div className='task-reward-navigation'>
          <ul>
          {taskRewards.map((taskReward:TaskReward, index:number) => 
            <li key={taskReward.id}><button className={selectedTaskReward === index ? 'task-reward-selected' : ''} onClick={() => setSelectedTaskReward(index)}>{taskReward.title}</button></li>
          )}
          </ul>
        </div>
        
        <div>
          {taskRewards.filter((taskReward:TaskReward, index:number) => index === selectedTaskReward).map((taskReward:TaskReward) => 
            <div key={taskReward.id}>
              <h3>{taskReward.title}</h3>
              <p>{taskReward.description}</p>
              <div className='reward-instructions'>
            <p>Hold the required rank at the completion of the task to win rewards. Once generated, rewards will be mintable via your profile, a standard network transaction fee is required.</p>
        </div>
              <div className='tier-list'>
                {taskReward.tiers.map((tier:RewardTier, tierIndex:number) =>
                  <Fragment key={tier.name}>
                    {tier.rewardTokens.map((token:RewardToken) =>
                    <div key={token.id} className={'reward-token ' + (userHasReward(token.id) ? 'current-user' : '')}>
                      
                      <div className='reward-rank'><span className='rank-number'>Rank {token.edition}{taskReward.variableTotalEditions && tierIndex +1 === taskReward.tiers.length ? '+' : ''}</span> <span className='tier-number'>Tier {tier.name}</span>
                      <div className='prospective'>{task.percentComplete < 100 ? 'PROSPECTIVE' : 'YOUR REWARD'}</div>
                      </div>
                      
                      <div><FigureDisplay token={token} size='quarter' /></div>
                      
                      
                      
                      <FigureDetail figure={taskReward.figure} edition={token.edition} totalEditions={taskReward.variableTotalEditions ? 'xx' : taskReward.figure.totalEditions} />

                      </div>
                    )}
                    </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      </>}
      {task && task.type === 2 &&
        <>
      <h1>{task.name}</h1>
      <div>
      <div className='task-menu'>
            <ul>
                <li><NavLink to={`../world`} end>World</NavLink></li>
              
                <li><NavLink to={`../world/${task.alias}/rewards`} end>Rewards</NavLink></li>
                <li><NavLink to={`../world/${task.alias}/history`} end>History</NavLink></li>
            </ul>
        </div>
      </div>
      <div className='task-rewards-page-content'>
        <h2>Rewards</h2>
        <div className='reward-instructions'>
            <p>Complete pacts before the task ends. Rewards will be mintable via your forge, a standard network transaction fee is required.</p>
        </div>
        <div className='progress-rewards'>
          <div className='progress-rewards-bar'>

          </div>
          <div className='progress-rewards-bar'>

          </div>
          
          <div>
              <h3>Form 20 Pacts (Pact Makers)</h3>
              <p>All Pact Makers will receive 1 random Base Element, Blood Stained Figure</p>
              
        </div>
          <div className='reward-rotation-display'>
          {taskRewards.filter((taskReward:TaskReward) => taskReward.title === 'Blood Pact Normal 1').map((taskReward:TaskReward) => 
            <>
              {taskReward.tiers.map((tier:RewardTier) => 
                <div>
                <div className='figure-rotation fadecycle5'>
                {tier.rewardTokens.map((token:RewardToken) =>
                  <div key={token.id} className={'reward-token '}>
                      
                      
                      
                      <div><FigureDisplay token={token} size='half' /></div>
                      
                      
                      
                      
                      </div>
                  )}
                  

                  </div>
                  <FigureDetail figure={taskReward.figure}  />
                  </div>
              )}
            </>
          )}
          </div>

          <div>
              <h3>Form 20 Pacts (Blood Givers)</h3>
              <p>All Blood Givers will receive 1 random Base Element Figure</p>
              
        </div>
          <div className='reward-rotation-display'>
          {taskRewards.filter((taskReward:TaskReward) => taskReward.title === 'Blood Pact Final 1').map((taskReward:TaskReward) => 
            <>
              {taskReward.tiers.map((tier:RewardTier) => 
                <div>
                <div className='figure-rotation fadecycle5'>
                {tier.rewardTokens.map((token:RewardToken) =>
                  <div key={token.id} className={'reward-token '}>
                      
                      
                      
                      <div><FigureDisplay token={token} size='half' /></div>
                      
                      
                      
                      
                      </div>
                  )}
                  {/* <FigureDetail figure={taskReward.figure}  /> */}

                  </div>
                  <FigureDetail figure={taskReward.figure}  />
                </div>
              )}
            </>
          )}
        </div>
        
        <div>
              <h3>Form 30 Pacts (Pact Makers)</h3>
              <p>All Pact Makers will receive 1 random Memory or Vision Figure, Blood Stained Figure</p>
        </div>
          <div className='reward-rotation-display'>
{taskRewards.filter((taskReward:TaskReward) => taskReward.title === 'Blood Pact Normal 2').map((taskReward:TaskReward) => 
            <>
              {taskReward.tiers.map((tier:RewardTier) => 
                <div>
                <div className='figure-rotation fadecycle5'>
                {tier.rewardTokens.map((token:RewardToken) =>
                  <div key={token.id} className={'reward-token '}>
                      
                      
                      
                      <div><FigureDisplay token={token} size='half' /></div>
                      
                      
                      
                      
                      </div>
                  )}
                  {/* <FigureDetail figure={taskReward.figure}  /> */}

                  </div>
                  <FigureDetail figure={taskReward.figure}  />
                </div>
              )}
            </>
          )}
</div>
<div>
              <h3>Form 40 Pacts (Pact Makers)</h3>
              <p>All Pact Makers will receive 1 random Death or Life Figure, Blood Stained Figure</p>
              
        </div>
          <div className='reward-rotation-display'>
{taskRewards.filter((taskReward:TaskReward) => taskReward.title === 'Blood Pact Normal 3').map((taskReward:TaskReward) => 
            <>
              {taskReward.tiers.map((tier:RewardTier) => 
                <div>
                <div className='figure-rotation fadecycle5'>
                {tier.rewardTokens.map((token:RewardToken) =>
                  <div key={token.id} className={'reward-token '}>
                      
                      
                      
                      <div><FigureDisplay token={token} size='half' /></div>
                      
                      
                      
                      
                      </div>
                  )}
                  {/* <FigureDetail figure={taskReward.figure}  /> */}

                  </div>
                  <FigureDetail figure={taskReward.figure}  />
                </div>
              )}
            </>
          )}
        
        </div>
        </div>
        
      </div>
      </>}
    </div>
  )
}

export default TaskRewardsPage;