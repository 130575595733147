import React from 'react'

import { AppContext } from '../../AppContext';
import MintableList from '../../components/MintableList';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';
import PageNav from '../../components/PageNav';
import PageWrapper from '../../components/PageWrapper';
import User from '../../interfaces/User';
import './ForgePage.scss';

interface IProps {
  
}

const ForgePage = (props:IProps) => {

  const context = React.useContext(AppContext);
  const session = context.userSession;
  const user = session?.user || {} as User;

  return (
    <div className="forge-page fade-in">
        <PageWrapper>
        <PageHeader>Forge</PageHeader>
        <PageNav children={undefined}></PageNav>
        <PageContent>
            <MintableList />
        </PageContent>
      </PageWrapper>
    </div>
  )
}

export default ForgePage;