import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import './EpochPage.scss';
import FirstEpoch from './FirstEpoch';
import SecondEpoch from './SecondEpoch';

interface IProps {
  
}

const EpochPage = (props:IProps) => {


  const epoch1ref = React.useRef<HTMLDivElement>(null);
  const epoch2ref = React.useRef<HTMLDivElement>(null);
  const context = React.useContext(AppContext);

  const [epoch , setEpoch] = useState<number>(2);

  return (
    <div className="epoch-page">
      <div className='tab-nav'>
      <ul>
        <li>
          <a onClick={() => setEpoch(1)}>The First Epoch</a>
        </li>
        <li>
          <a onClick={() => setEpoch(2)}>The Second Epoch</a>
        </li>
      </ul>
      <span style={{transform: `translate3d(${((epoch -1) * 100)}%, 0, 0)`}} className='underline'></span>
      </div>
      <div className='tab-epoch-wrapper' style={{height : (epoch === 1 ? epoch1ref.current?.clientHeight || 0 : epoch2ref.current?.clientHeight || 0)}}>
      <span className='ink'>
            <img src="/epoch/ink.png" />
          </span>
      <div ref={epoch1ref} className={'epoch-section ' + (epoch === 1 ? 'show' : '')}>
        {epoch === 1 &&
      <FirstEpoch />
    }
      </div>
      <div ref={epoch2ref} className={'epoch-section ' + (epoch === 2 ? 'show' : '')}>
      {epoch === 2 &&
      <SecondEpoch />
      }
      </div>
      
      </div>
    </div>
  )
}

export default EpochPage;