import './PageWrapper.scss';
interface IProps {
    children?: JSX.Element | JSX.Element[];
}

const PageWrapper = (props:IProps) => {

return (
    <div className="page-wrapper">
      {props.children}
    </div>
  )
}

export default PageWrapper;