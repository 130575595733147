import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Web3 from 'web3';

import { AppContext } from '../../AppContext';
import MintableToken from '../../interfaces/MintableTokens';

import paneImg from "../../image/mastery-bar-pane.png";
import capImg from "../../image/mastery-bar-cap.png";

import './MasteryBar.scss';
import ElementMasteryLevel from '../../interfaces/ElementMasteryLevel';



interface IProps {
    elementId:number;
    masteryLevel:ElementMasteryLevel;
    onResearchNextLevel(nextLevel:ElementMasteryLevel):void;
}

const MasteryBar = (props: IProps) => {

    const context = React.useContext(AppContext);

    function getPercentage(): number {
        return (100 * props.masteryLevel.userMasteryExp.length) / props.masteryLevel.expRequirement;
    }

    function getPendingPercentage(part:number, whole:number): number {
       let percent =  (100 * part) / whole;
       return 100 - percent;
    }
    
    function getAllExp(count:number):number[] {
        let exp:number[] = [];
        for(let i = 0;i<count;i++) {
            exp.push(i);
        }
        return exp;
      }

    return (
        <div className={'mastery-bar element-' + props.elementId}  onClick={() => props.onResearchNextLevel(props.masteryLevel)}>
            <div className='mastery-bar-pane'>
            <img src={paneImg} />
            </div>
            <div className='mastery-bar-track'>
                <div className={'texture-overlay element-' + props.elementId} style={{width : getPercentage() + '%' }}></div>
                <div className='mastery-bar-segments' style={{backgroundPosition : '0 ' + (props.elementId * 100) + 'px'}}>
                {getAllExp(props.masteryLevel.expRequirement).map((number:number) => 
                    <div className='segment'>
                        {props.masteryLevel.userMasteryExp[number] && 
                                <>
                                {props.masteryLevel.userMasteryExp[number].status == 2 && 
                                    <div className={'segment-vial vial-' + props.elementId}></div>
                                }
                                {props.masteryLevel.userMasteryExp[number].status == 1 && 
                                    <div className={'segment-vial vial-' + props.elementId} style={{width : getPendingPercentage(props.masteryLevel.userMasteryExp[number].secondsRemaining, props.masteryLevel.userMasteryExp[number].totalSeconds)}}></div>
                                }
                                </>
                        }
                    </div>
                )}
                </div>
            </div>
            <div className='mastery-bar-cap'>
            <img src={capImg} />
            </div>
        </div>
    )
}

export default MasteryBar;