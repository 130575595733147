import axios from 'axios';
import { connect } from 'http2';
import React, { Fragment } from 'react'
import { useState, useEffect } from 'react';

import { Link, useParams, Outlet, NavLink } from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import RankingCard from '../../components/RankingCard';
import SortArrows from '../../components/SortArrows';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import CriteriaRank from '../../interfaces/CriteriaRank';
import FigureDetail from '../../interfaces/FigureDetail';
import Page from '../../interfaces/Page';
import Task from '../../interfaces/Task';
import TaskContributionElement from '../../interfaces/TaskContributionElement';
import User from '../../interfaces/User';
import UserTaskRank from '../../interfaces/UserTaskRank';
import './TaskRankingsPage.scss';

interface IProps {

}

const TaskRankingsPage = (props: IProps) => {
  const defaultCriteria:CriteriaRank = {
    sortOn : 'totalRank',
    direction : 'asc',
  }

  const context = React.useContext(AppContext);
  const { taskAlias } = useParams();
  const user: User = context.user || {} as User;

  const [task, setTask] = React.useState<Task>();
  const [activeElements, setActiveElements] = React.useState<number[]>([]);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [pages, setPages] = React.useState<Page[]>([]);
  const [sortPages, setSortPages] = React.useState<Page[]>([]);
  const [criteria, setCriteria] = React.useState<CriteriaRank>(defaultCriteria);

  const [sortOn, _setSortOn] = React.useState<string>(defaultCriteria.sortOn);
const [reverse, _setReverse] = React.useState<boolean>(defaultCriteria.direction === 'desc' ? true : false);
const [rankView, _setRankView] = React.useState<string>('totalRank');
function setReverse(reverse:boolean) {
  _setReverse(reverse);
  //localStorage.setItem("summaryReverse", String(reverse));
}

function setRankView(view:string) {
  _setRankView(view);
}

function setSortOn(sort:string) {
      if (sortOn === sort && reverse) {
        setReverse(false);
      }
      if (sortOn === sort && !reverse) {
        _setSortOn('default');
        setReverse(false);
        //localStorage.setItem("summarySort", 'compareId');
      }
      if (sortOn !== sort) {
        _setSortOn(sort);
        setReverse(true);
        //localStorage.setItem("summarySort", sort);
      }
      
}

  function getFormKey():string {
    return 'key' +  criteria.sortOn + criteria.direction; 
  }
  useEffect(() => {
    let newCriteria = {...criteria};
    newCriteria.sortOn = sortOn;
    newCriteria.direction = (reverse ? 'desc' : 'asc');
    setCriteria(newCriteria);
 }, [reverse, sortOn]);
 
 useEffect(() => {
   //setListSize(0);
}, [getFormKey()]);

  React.useEffect(() => {

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${taskAlias}`;
    //context.setShowLoader?.('Loading Task', true);
    axios({
      "method": "GET",
      "url": url
    })
      .then((response) => {
        let task: Task = response.data;
        if (task) {
          let elements: number[] = [];
          task.contribution.elements.forEach((element: TaskContributionElement) =>
            elements.push(element.id)
          );
          setActiveElements(elements);
        }
        setTask(task);
        //context.setShowLoader?.('Loading Tasks', false);
      })
      .catch((error) => {
        console.log(error);
        //context.setShowLoader?.('Loading Tasks', false);
      })

  }, []);

  React.useEffect(() => {

    if (task && task.id) {
      let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${task.id}/rankings/${pageNumber}`;
      context.setShowLoader?.('Loading Rankings', true);
      axios({
        "method": "GET",
        "url": url,
        'params' : criteria
      })
        .then((response) => {
          let rankPage: Page = response.data;
          //let updatedPages = pages;
          //updatedPages.push(rankPage);
          setPages([rankPage]);
          setSortPages([rankPage]);
          context.setShowLoader?.('Loading Rankings', false);
        })
        .catch((error) => {
          console.log(error);
          context.setShowLoader?.('Loading Rankings', false);
        })
    }



  }, [task?.id, getFormKey()]);


  function sortRankView(a:UserTaskRank, b:UserTaskRank):number {
    switch (rankView) {
      case 'totalRank':
        return a.totalRank - b.totalRank;
      case 'death':
        return b.death - a.death;
      case 'life':
        return b.life - a.life;
      case 'fire':
        return b.fire - a.fire;
      case 'water':
        return b.water - a.water;
      case 'earth':
        return b.earth - a.earth;
      case 'air':
        return b.air - a.air;
      case 'memory':
        return b.memory - a.memory;
      case 'vision':
        return b.vision - a.vision;
      }
      return 0;
  }
 
  return (
    <div className="task-rankings-page fade-in">
      {task && task.type === 1 && 
        <>
      <h1>{task.name}</h1>
      <div>
      <div className='task-menu'>
            <ul>
                <li><NavLink to={`../world`} end>World</NavLink></li>
                <li><NavLink to={`../world/${task.alias}/rankings`} end>Rankings</NavLink></li>
                <li><NavLink to={`../world/${task.alias}/rewards`} end>Rewards</NavLink></li>
                <li><NavLink to={`../world/${task.alias}/history`} end>History</NavLink></li>
            </ul>
        </div>
      </div>
      <div className='task-rankings-page-content'>
      <h2>Rankings</h2>
      <div className='table-view'>
      {task &&
        <>
          <table>
            <thead>
              <tr>
                <th className={"align-left sort-option " + (sortOn === 'totalRank' ? 'selected' : '')} onClick={() => setSortOn('totalRank')}>Rank <SortArrows sort={'totalRank'} reverse={reverse} currentSort={sortOn} /></th>
                <th className={"align-left sort-option " + (sortOn === 'userName' ? 'selected' : '')} onClick={() => setSortOn('userName')}>Participant <SortArrows sort={'userName'} reverse={reverse} currentSort={sortOn} /></th>
                <th className={"sort-option " + (sortOn === 'totalPercent' ? 'selected' : '')} onClick={() => setSortOn('totalPercent')}>Total % <SortArrows sort={'totalPercent'} reverse={reverse} currentSort={sortOn} /></th>
                {activeElements?.includes(1) && <th className={"sort-option " + (sortOn === 'death' ? 'selected' : '')} onClick={() => setSortOn('death')}>Death <SortArrows sort={'death'} reverse={reverse} currentSort={sortOn} /></th>}
                {activeElements?.includes(2) && <th className={"sort-option " + (sortOn === 'life' ? 'selected' : '')} onClick={() => setSortOn('life')}>Life <SortArrows sort={'life'} reverse={reverse} currentSort={sortOn} /></th>}
                {activeElements?.includes(3) && <th className={"sort-option " + (sortOn === 'fire' ? 'selected' : '')} onClick={() => setSortOn('fire')}>Fire <SortArrows sort={'fire'} reverse={reverse} currentSort={sortOn} /></th>}
                {activeElements?.includes(4) && <th className={"sort-option " + (sortOn === 'water' ? 'selected' : '')} onClick={() => setSortOn('water')}>Water <SortArrows sort={'water'} reverse={reverse} currentSort={sortOn} /></th>}
                {activeElements?.includes(5) && <th className={"sort-option " + (sortOn === 'earth' ? 'selected' : '')} onClick={() => setSortOn('earth')}>Earth <SortArrows sort={'earth'} reverse={reverse} currentSort={sortOn} /></th>}
                {activeElements?.includes(6) && <th className={"sort-option " + (sortOn === 'air' ? 'selected' : '')} onClick={() => setSortOn('air')}>Air <SortArrows sort={'air'} reverse={reverse} currentSort={sortOn} /></th>}
                {activeElements?.includes(7) && <th className={"sort-option " + (sortOn === 'memory' ? 'selected' : '')} onClick={() => setSortOn('memory')}>Memory <SortArrows sort={'memory'} reverse={reverse} currentSort={sortOn} /></th>}
                {activeElements?.includes(8) && <th className={"sort-option " + (sortOn === 'vision' ? 'selected' : '')} onClick={() => setSortOn('vision')}>Vision <SortArrows sort={'vision'} reverse={reverse} currentSort={sortOn} /></th>}
              </tr>
            </thead>
            <tbody>
              {pages.map((page: Page) =>
                <Fragment key={page.number}>
                  {page.content.map((userRank: UserTaskRank) =>
                    <tr key={userRank.totalRank} className={'fade-in-fast ' + (userRank.userId === context?.userSession?.user?.id ? 'current-user' : '')}>
                      <td className='total-rank'>{userRank.totalRank}</td>
                      <td className='rank-username'>{userRank.userName}</td>
                      <td className='rank-percent'>{userRank.totalPercent.toFixed(3)}%</td>
                      {activeElements?.includes(1) && (userRank.death ? <td><span className='element-amount'>{userRank.death}</span> <span className='element-rank'>#{userRank.deathRank} {Number(userRank.deathPercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                      {activeElements?.includes(2) && (userRank.life ? <td><span className='element-amount'>{userRank.life}</span> <span className='element-rank'>#{userRank.lifeRank} {Number(userRank.lifePercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                      {activeElements?.includes(3) && (userRank.fire ? <td><span className='element-amount'>{userRank.fire}</span> <span className='element-rank'>#{userRank.fireRank} {Number(userRank.firePercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                      {activeElements?.includes(4) && (userRank.water ? <td><span className='element-amount'>{userRank.water}</span> <span className='element-rank'>#{userRank.waterRank} {Number(userRank.waterPercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                      {activeElements?.includes(5) && (userRank.earth ? <td><span className='element-amount'>{userRank.earth}</span> <span className='element-rank'>#{userRank.earthRank} {Number(userRank.earthPercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                      {activeElements?.includes(6) && (userRank.air ? <td><span className='element-amount'>{userRank.air}</span> <span className='element-rank'>#{userRank.airRank} {Number(userRank.airPercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                      {activeElements?.includes(7) && (userRank.memory ? <td><span className='element-amount'>{userRank.memory}</span> <span className='element-rank'>#{userRank.memoryRank} {Number(userRank.memoryPercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                      {activeElements?.includes(8) && (userRank.vision ? <td><span className='element-amount'>{userRank.vision}</span> <span className='element-rank'>#{userRank.visionRank} {Number(userRank.visionPercent.toFixed(2))}%</span></td> : <td className='empty'>-</td>)}
                    </tr>
                  )}
                </Fragment>
              )}
            </tbody>
          </table>
          {Boolean(pages.length > 0) && <div className='end-of-list'>THE END</div>}
        </>
      }
      </div>
      <div className='list-view'>
      {task &&
        <>
            {/* <ul className='list-view-sort'>
                <li className={"align-left sort-option " + (sortOn === 'totalRank' ? 'selected' : '')} onClick={() => setSortOn('totalRank')}>Rank <SortArrows sort={'totalRank'} reverse={reverse} currentSort={sortOn} /></li>
                <li className={"align-left sort-option " + (sortOn === 'userName' ? 'selected' : '')} onClick={() => setSortOn('userName')}>Participant <SortArrows sort={'userName'} reverse={reverse} currentSort={sortOn} /></li>
                <li className={"sort-option " + (sortOn === 'totalPercent' ? 'selected' : '')} onClick={() => setSortOn('totalPercent')}>Total % <SortArrows sort={'totalPercent'} reverse={reverse} currentSort={sortOn} /></li>
                {activeElements?.includes(1) && <li className={"sort-option " + (sortOn === 'death' ? 'selected' : '')} onClick={() => setSortOn('death')}>Death <SortArrows sort={'death'} reverse={reverse} currentSort={sortOn} /></li>}
                {activeElements?.includes(2) && <li className={"sort-option " + (sortOn === 'life' ? 'selected' : '')} onClick={() => setSortOn('life')}>Life <SortArrows sort={'life'} reverse={reverse} currentSort={sortOn} /></li>}
                {activeElements?.includes(3) && <li className={"sort-option " + (sortOn === 'fire' ? 'selected' : '')} onClick={() => setSortOn('fire')}>Fire <SortArrows sort={'fire'} reverse={reverse} currentSort={sortOn} /></li>}
                {activeElements?.includes(4) && <li className={"sort-option " + (sortOn === 'water' ? 'selected' : '')} onClick={() => setSortOn('water')}>Water <SortArrows sort={'water'} reverse={reverse} currentSort={sortOn} /></li>}
                {activeElements?.includes(5) && <li className={"sort-option " + (sortOn === 'earth' ? 'selected' : '')} onClick={() => setSortOn('earth')}>Earth <SortArrows sort={'earth'} reverse={reverse} currentSort={sortOn} /></li>}
                {activeElements?.includes(6) && <li className={"sort-option " + (sortOn === 'air' ? 'selected' : '')} onClick={() => setSortOn('air')}>Air <SortArrows sort={'air'} reverse={reverse} currentSort={sortOn} /></li>}
                {activeElements?.includes(7) && <li className={"sort-option " + (sortOn === 'memory' ? 'selected' : '')} onClick={() => setSortOn('memory')}>Memory <SortArrows sort={'memory'} reverse={reverse} currentSort={sortOn} /></li>}
                {activeElements?.includes(8) && <li className={"sort-option " + (sortOn === 'vision' ? 'selected' : '')} onClick={() => setSortOn('vision')}>Vision <SortArrows sort={'vision'} reverse={reverse} currentSort={sortOn} /></li>}
            </ul> */}
            <div className='list-view-menu'>
            <select value={rankView} onChange={(e) => setRankView(e.target.value)}>
              <option value='totalRank'>Total</option>
              {activeElements?.includes(1) && <option value='death'>Death</option>}
              {activeElements?.includes(2) && <option value='life'>Life</option>}
              {activeElements?.includes(3) && <option value='fire'>Fire</option>}
              {activeElements?.includes(4) && <option value='water'>Water</option>}
              {activeElements?.includes(5) && <option value='earth'>Earth</option>}
              {activeElements?.includes(6) && <option value='air'>Air</option>}
              {activeElements?.includes(7) && <option value='memory'>Memory</option>}
              {activeElements?.includes(8) && <option value='vision'>Vision</option>}
            </select>
            </div>
            <div className={'list-view-header '  + (rankView === 'totalRank' ? '' : 'is-element')}>
              <div>#</div>
              <div>Participant</div>
              {rankView !== 'totalRank' && <div>Amount</div>}
              <div>Percent</div>
            </div>
            <ul className='list-view-results'>
              {sortPages.map((page: Page) =>
                <Fragment key={page.number}>
                  {page.content.slice().sort(sortRankView).map((userRank: UserTaskRank) =>
                    <li key={userRank.userId} className={'fade-in-fast ' + (userRank.userId === context?.userSession?.user?.id ? 'current-user' : '')}>
                      <RankingCard userRank={userRank} activeElements={activeElements} view={rankView} />
                    </li>
                  )}
                </Fragment>
              )}
            </ul>
            
          {Boolean(sortPages.length > 0) && <div className='end-of-list'>THE END</div>}
        </>
      }
      </div>
      </div>
      </>}
    </div>
  )
}

export default TaskRankingsPage;