import './PageNav.scss';
interface IProps {
    children: JSX.Element | JSX.Element[] | undefined;
}

const PageNav = (props:IProps) => {

return (
    <div className="page-nav">
      {props.children}
    </div>
  )
}

export default PageNav;