import './PageHeader.scss';
interface IProps {
    children: JSX.Element | JSX.Element[] | string;
}

const PageHeader = (props:IProps) => {

return (
    <h1 className="page-header">
      {props.children}
    </h1>
  )
}

export default PageHeader;