import React, { RefObject, useCallback, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import Timer from './Countdown';
import Countdown from './Countdown';
import axios from 'axios';

// import audio from './audio';
import Viewer from './Viewer';
import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';
import Inventory from './components/Inventory';
import Figure from './components/Figure';
import FigureDetail from './interfaces/FigureDetail';
import { AppContext } from './AppContext';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ConnectPage from './pages/ConnectPage';
import UserBoard from './interfaces/UserBoard';
import ItemSwapPost from './interfaces/ItemSwapPost';
import Balances from './interfaces/Balances';
import Balance from './components/Balance';
import InventoryHoriz from './components/InventoryHoriz';
import User from './interfaces/User';

import UserSession from './interfaces/UserSession';
import AboutPage from './pages/AboutPage';
import UserManager from './components/UserManager';
import ExperimentNav from './components/ExperimentNav';
import ExperimentPage from './pages/ExperimentPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import WorldPage from './pages/WorldPage';
import UserNameChangePage from './pages/UserNameChangePage';
import TaskRankingsPage from './pages/TaskRankingsPage';
import TaskHistoryPage from './pages/TaskHistoryPage';
import TaskRewardsPage from './pages/TaskRewardsPage';
import ForgePage from './pages/ForgePage';
import MasteryPage from './pages/MasteryPage';

import SupplyPage from './pages/SupplyPage';
import ExpUserBoard from './interfaces/ExpUserBoard';
import ExpUserBoardSlot from './interfaces/ExpUserBoardSlot';

declare var window: any;

interface IProps {
  userSession: UserSession;
  connect(): void;
}


const App = (props: IProps) => {
  //const location = useLocation()
  var eventSource: EventSource | undefined;
  const auth: boolean = (props.userSession.user?.id ? true : false);
  const signature = props.userSession.signature;
  const originalMessage = props.userSession.message;
  const accountAddress = props.userSession.address;
  //const position = useMousePosition();
  // const AudioContext = window.AudioContext || window.webkitAudioContext;
  // const audioCtx = new AudioContext();
  

  //const [board, setBoard] = React.useState<(FigureDetail | undefined)[]>(buildBoard());

  const [userFigures, setUserFigures] = React.useState<FigureDetail[]>([]);
  const [userFigureMap, _setUserFigureMap] = React.useState<Map<number, FigureDetail>>(new Map<number, FigureDetail>());
  //const [boardRevision, setBoardRevision] = React.useState<number>(0);
  const [user, setUser] = React.useState<User>();
  const [saveMessage, setSaveMessage] = React.useState<string>('');
  const [showLoader, _setShowLoader] = React.useState<boolean>(false);
  const [userBoard, setUserBoard] = React.useState<ExpUserBoard>();
  

function useBooleanState(initialValue:any) {
  const [value, setValue] = React.useState<boolean>(false);
  const setTrue = useCallback(() =>setValue(true), [])
  const setFalse = useCallback(() =>setValue(false), [])
  return [value, setTrue, setFalse];

}
const[playing, isPlaying, isNotPlaying] = useBooleanState(false);




  // const [auth, setAuth] = React.useState<boolean>(false);
  // const [authUser, setAuthUser] = React.useState<User>();
  // const [originalMessage, _setOriginalMessage] = React.useState(getDefaultStateString('originalMessage'));
  // const [signature, _setSignature] = React.useState('');
  // const [accountAddress, _setAccountAddress] = React.useState<string>(getDefaultStateString('account'));

  const [balances, setBalances] = React.useState<Balances>({} as Balances);


  function setShowLoader(message: string, show: boolean): void {
    setSaveMessage(message);
    _setShowLoader(show);
  }

  function getDefaultStateString(key: string): string {
    let string = localStorage.getItem(key)
    return string ? JSON.parse(string) : '';
  }



  // function setUserFiguresMap(figures: FigureDetail[]) {
  //   let figureMap = new Map<number, FigureDetail>();
  //   for (var figure of figures) {
  //     figureMap.set(figure.id, figure);
  //   }
  //   _setUserFigureMap(new Map(figureMap));
  //   setBoardRevision(boardRevision + 1);
  // }

  // function buildBoard(): (FigureDetail | undefined)[] {
  //   //build initial inventory
  //   let inventory: (FigureDetail | undefined)[] = [];
  //   for (let i = 0; i <= 120; i++) {
  //     inventory.push(undefined);
  //   }
  //   return inventory;
  // }

  

// function playSound2() {
//   console.log(checked);
//   if(!isSongPlaying) { // the proper way to access the new updated state is here
  
//     // const AudioContext = window.AudioContext || window.webkitAudioContext;
//     // const audioCtx = new AudioContext();

//     // var som = new Audio(song);
//     // som.src = "./audio/Drone.wav";
//     // som.crossOrigin = 'anonymous';

//     // var som2 = new Audio(song2);
//     // som2.src = "./audio/Theme.wav";
//     // som2.crossOrigin = 'anonymous';
//     // console.log(playing);

//     // console.log(som.src);


//     som2.load();
//     som2.play();
//     som.load();
//     som.play();
//     setPlaying();
//   }
// }
// }
  

  React.useEffect(() => {

    if (signature) {

      updateUserFigures();
      // let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getInventory`;

      // axios({
      //   "method": "GET",
      //   "url": url,
      //   "headers": {
      //     "signedMessage": signature as string,
      //     "originalMessage": originalMessage as string,
      //     "address": accountAddress as string
      //   }
      // })
      //   .then((response) => {
      //     let inventory: FigureDetail[] = response.data;
      //     setUserFigures(inventory);
      //     setUserFiguresMap(inventory);
      //     if (inventory.length) {
      //       getBoard(inventory);
      //     }

      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })

        let boardUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getBoard2`;

      axios({
        "method": "GET",
        "url": boardUrl,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
      })
        .then((response) => {
          let userBoard:ExpUserBoard = response.data;
          setUserBoard(userBoard);

        })
        .catch((error) => {
          console.log(error)
        })

      let getUserUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getUser`;

      axios({
        "method": "GET",
        "url": getUserUrl,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
      })
        .then((response) => {
          let getUser: User = response.data;
          setUser(getUser);
        })
        .catch((error) => {
          console.log(error)
        })


        let getUserBalanceUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getBalance`;

      axios({
        "method": "GET",
        "url": getUserBalanceUrl,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
      })
        .then((response) => {
          let getBalance:Balances = response.data;
          setBalances(getBalance);
        })
        .catch((error) => {
          console.log(error)
        })

    }


  }, [auth]);

  // function getBoard(inventory: FigureDetail[]) {


  //   let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getBoard`;

  //   axios({
  //     "method": "GET",
  //     "url": url,
  //     "headers": {
  //       "signedMessage": signature as string,
  //       "originalMessage": originalMessage as string,
  //       "address": accountAddress as string
  //     }
  //   })
  //     .then((response) => {
  //       let newBoard = [...board];
  //       let userBoardLocations: UserBoard[] = response.data;

  //       for (var userBoard of userBoardLocations) {
  //         for (var figureToken of inventory) {
  //           if (figureToken.id === userBoard.tokenId) {
  //             newBoard[userBoard.boardLocation] = figureToken;
  //           }

  //         }
  //       }
  //       //console.log(newBoard);
  //       setBoard([...newBoard]);
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })


  // }

  // function updateBoard(updatedboard: UserBoard[]) {
  //   let newBoard = buildBoard();
  //   for (var userBoard of updatedboard) {
  //     for (var figureToken of userFigures) {
  //       if (figureToken.id === userBoard.tokenId) {
  //         newBoard[userBoard.boardLocation] = figureToken;
  //       }
  //     }
  //   }
  //   console.log(newBoard);
  //   setBoard([...newBoard]);
  // }


  // function removeFigure(dragItem: FigureDetail) {

  //   let oldLocation = findFigureLocation(dragItem);

  //   let newBoard = [...board];
  //   //var tmp = newBoard[newPosition];
  //   //newBoard[newPosition] = newBoard[oldLocation];
  //   newBoard[oldLocation] = undefined;

  //   let itemSwap = {} as ItemSwapPost;
  //   itemSwap.firstItem = dragItem.id;
  //   itemSwap.firstItemLocation = oldLocation;
  //   itemSwap.remove = true;
  //   swapItems(itemSwap);

  //   setBoard(newBoard);


  // }

  // function swap(newPosition: number, dragItem: FigureDetail, remove?:boolean) {
  //   if (remove) {
  //     removeFigure(dragItem);
  //   } else {
  //     let oldLocation = findFigureLocation(dragItem);

  //     let newBoard = [...board];
  //     var tmp = newBoard[newPosition];
  //     newBoard[newPosition] = newBoard[oldLocation];
  //     newBoard[oldLocation] = tmp;
  
  //     let itemSwap = {} as ItemSwapPost;
  //     itemSwap.firstItem = dragItem.id;
  //     itemSwap.firstItemLocation = oldLocation;
  //     itemSwap.secondItem = tmp?.id || 0;
  //     itemSwap.secondItemLocation = newPosition;
  //     swapItems(itemSwap);
  
  //     setBoard(newBoard);
  //   }
    
  // }

  function directUpdateUserBoard(slot:ExpUserBoardSlot, figure:FigureDetail) {
    let updatedUserBoard = userBoard || {} as ExpUserBoard;
    let currentSlot:ExpUserBoardSlot|undefined;
    let updatedSlot:ExpUserBoardSlot|undefined;
    for (let i = 0; i < updatedUserBoard.slots.length; i++) {
      if (updatedUserBoard.slots[i].id === slot.id) {
        updatedSlot = updatedUserBoard.slots[i];
      }
      if (updatedUserBoard.slots[i].token?.id === figure.id) {
        currentSlot = updatedUserBoard.slots[i];
      }
    }
    if(currentSlot) {
      currentSlot.token = undefined;
    }
    if(updatedSlot?.token && currentSlot) {
      currentSlot.token = updatedSlot.token;
    }
    if(updatedSlot) {
      updatedSlot.token = figure;
    }
    setUserBoard(updatedUserBoard);
  }

  function directRemoveUserBoard(figure:FigureDetail) {
    let updatedUserBoard = userBoard || {} as ExpUserBoard;
    let currentSlot:ExpUserBoardSlot|undefined;
    
    for (let i = 0; i < updatedUserBoard.slots.length; i++) {
      if (updatedUserBoard.slots[i].token?.id === figure.id) {
        currentSlot = updatedUserBoard.slots[i];
      }
    }
    if(currentSlot) {
      currentSlot.token = undefined;
    }
    setUserBoard(updatedUserBoard);
  }

  function placeFigureOnUserBoard(slot:ExpUserBoardSlot, figure:FigureDetail) {
    
    directUpdateUserBoard(slot, figure);

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/setBoard2`;
    
    axios({
      "method": "POST",
      "url": url,
      "headers": {
          "signedMessage": signature,
          "originalMessage": originalMessage,
          "address": accountAddress,
          'content-type': 'application/json'
      },
      "data": {userBoardSlotId: slot.id, tokenId: figure.id}

    })
      .then((response) => {
         // let updatedUser:User = response.data;
          //let updatedBoard = response.data;
          //setUserBoard(updatedBoard);
          //context.setShowLoader?.(`Minting ${mintableToken.tokenDetail.name} ${mintableToken.tokenDetail.edition}/${mintableToken.tokenDetail.totalEditions}`, false);
      })
      .catch((error) => {
          console.log(error);
      })
  }

  function removeFigureFromUserBoard(figure:FigureDetail) {
    directRemoveUserBoard(figure);

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/claimBoardToken`;
    
    axios({
      "method": "POST",
      "url": url,
      "headers": {
          "signedMessage": signature,
          "originalMessage": originalMessage,
          "address": accountAddress,
          'content-type': 'application/json'
      },
      "data": {userBoardSlotId: 1, tokenId: figure.id}

    })
      .then((response) => {
         // let updatedUser:User = response.data;
          //let updatedBoard = response.data;
          //setUserBoard(updatedBoard);
          //context.setShowLoader?.(`Minting ${mintableToken.tokenDetail.name} ${mintableToken.tokenDetail.edition}/${mintableToken.tokenDetail.totalEditions}`, false);
      })
      .catch((error) => {
          console.log(error);
      })
  }

  function updateUserFigures() {

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getFigures`;


    axios({
        "method": "GET",
        "params": { criteria: 'all=true' },
        "url": url,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
    })
        .then((response) => {
            let figures: FigureDetail[] = response.data;
            setUserFigures(figures);
            //context.setShowLoader?.(`Loading Figures`, false);
        })
        .catch((error) => {
            console.log(error);
            //context.setShowLoader?.(`Loading Figures`, false);
        });
  }

  // async function swapItems(itemSwap: ItemSwapPost) {


  //   let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/setBoard`;


  //   const result = await axios.post(
  //     url, itemSwap,
  //     {
  //       headers: {
  //         signedMessage: signature,
  //         originalMessage: originalMessage,
  //         address: accountAddress
  //       }
  //     }
  //   );

  // }


 
  // function findFigureLocation(figure: FigureDetail): number {
  //   for (let i = 0; i <= board.length; i++) {
  //     if (board[i]?.id === figure.id) {
  //       return i;
  //     }
  //   }
  //   return -1;
  // }
 
 

  // useEffect(() => {

  //   window.addEventListener('mouseup', onMouseUp);

  //   return () => {
  //     window.removeEventListener('mouseup', onMouseUp);

  //   }
  // }, []);

  // function setDragItem(figure: FigureDetail | undefined, offsetX: number, offsetY: number) {
  //   setOffset({ x: offsetX, y: offsetY });

  //   _setDragItem(figure);
  // }

  React.useEffect(() => {



    if (props.userSession.user?.id != null) {
      eventSource = new EventSource(`${process.env.REACT_APP_SERVICE_BASEPATH}/user/${props.userSession.user.id}/events`);

      eventSource.onmessage = e => setBalances(JSON.parse(e.data));

    }


    return () => {
      eventSource?.close();

    }



  }, [props.userSession.user?.id])



  return (
    <div className={"the-gold-crow fade-in " + (auth ? 'auth' : '')} >
      {/* <button onClick={() => setChecked(true)}>Play Sound</button>
      <button onClick={() => stopSound()}>Stop Sound</button> */}
      <AppContext.Provider value={{ placeFigureOnUserBoard: placeFigureOnUserBoard, removeFigureFromUserBoard : removeFigureFromUserBoard, userBoard : userBoard, updateUserBoard : setUserBoard, setShowLoader: setShowLoader, setUser: setUser, user: user, userSession: props.userSession, auth: auth, connect: props.connect, board: undefined, userFigureMap: userFigureMap, swap: undefined, balances: balances, setUserBalance: setBalances, updateBoard : undefined, userFigures : userFigures, updateUserFigures: updateUserFigures }} >
      
        <div className={"page-pane " + (auth ? 'auth' : '')}>
          <BrowserRouter>
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="experiment" element={<ExperimentPage />}>
                <Route index element={<UserPage balances={balances} />} />
                <Route path="world" element={<WorldPage />} />
                <Route path="world/:taskAlias/rankings" element={<TaskRankingsPage />} />
                <Route path="world/:taskAlias/history" element={<TaskHistoryPage />} />
                <Route path="world/:taskAlias/rewards" element={<TaskRewardsPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="profile/change-name" element={<UserNameChangePage />} />
                <Route path="forge" element={<ForgePage />} />
                <Route path="research" element={<MasteryPage />} />
                <Route path="supply" element={<SupplyPage />} />
              </Route>
              <Route path="/figure/:figureNumber/edition/:editionNumber/" element={<Viewer />} />
            </Routes>
          </BrowserRouter>
          {/* {dragItem && <div className={'drag-item ' + (dragItem ? 'show' : '')} style={{ transform: `translateX(${offset.x}px) translateY(${offset.y}px)`, left: position.x, top: position.y }}>
            <div className='drag-wrapper'>
              <Figure proxy={true} position={-1} figure={dragItem} />
            </div>
          </div>} */}
        </div>
        
      </AppContext.Provider>
      {showLoader && <div className='loading-indicator fade-in-fast pulse show'>
        <div className="pulse-loader"></div>
        {saveMessage}</div>}
    </div>
  );
  
}

export default App;

