
import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import './VerticalVial.scss';
interface IProps {
    total: number;
    amount: number;
    element:number;
    onClick(elementId:number):void;
}

const VerticalVial = (props: IProps) => {

    

    function getPercentage(part: number): number {
        return (100 * part) / props.total;
    }

    const percent = getPercentage(props.amount);

    function onClickHandler() {
        if (percent < 100) {
            props.onClick(props.element);
        }
    }

    function startingGlowOpacity():number {
        switch (true) {
            case percent <= 0:
                return 1;
            case percent <= 5:
                return 0.7;
            case percent <= 10:
                return 0.4;
            case percent <= 15:
                return 0.1;
        }
        return 0;
    }
    return (
        <div className={'vertical-vial-wrapper ' + (percent >= 100 ? 'complete' : '')}>
            <div className='vial-symbol' onClick={onClickHandler}>
                    <img style={{opacity : (.3 + (.7 * (percent / 100)))}} src={getSymbolImage(props.element)} />
                </div>
        <div className={"vertical-vial " + (percent >= 100 ? 'complete' : '')}>
            


                <div className='contents-mask' onClick={onClickHandler}>
                <span className={'glow glow-' + props.element} style={{ bottom: percent + '%' }}></span>
                <span className={'glow-start glow-' + props.element} style={{ opacity: startingGlowOpacity() }}></span>
                    <div className='balance-flask'>
                        <div style={{ height: percent + '%' }} className={'vial-' + props.element}></div>
                        
                    </div>
                    <div className='measurements'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className='vial-overlay'>
                    <span className='vial-top'></span>
                    <span className='vial-center'></span>
                    <span className='vial-bottom'></span>
                </div>
                
        </div>
        <div className='vial-label' onClick={onClickHandler}>
               {Math.floor(percent)}%
        </div>
        </div>
    )
}

export default VerticalVial;

function getSymbolImage(element: number):string {
    switch (element) {
        case 1:
            return '/ui/symbol_death.png';
        case 2:
            return '/ui/symbol_life.png';
        case 3:
            return '/ui/symbol_fire.png';
        case 4:
            return '/ui/symbol_water.png';
        case 5:
            return '/ui/symbol_earth.png';
        case 6:
            return '/ui/symbol_air.png';
        case 7:
            return '/ui/symbol_memory.png';
        case 8:
            return '/ui/symbol_vision.png';
    }
    return '/ui/symbol_death.png';
}