import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import UserTaskRank from '../../interfaces/UserTaskRank';

import './RankingCard.scss';
interface IProps {
    userRank:UserTaskRank;
    view:string;
    activeElements:number[];
}

const RankingCard = (props: IProps) => {

    const context = useContext(AppContext);
    const userRank = props.userRank;
    const activeElements = props.activeElements;

    const [showDetails, setShowDetails] = React.useState<boolean>(false);
    const detailRef = React.useRef<HTMLDivElement>(null);
    let totalRank = userRank.totalRank;
    let totalPercent = userRank.totalPercent;
    let amount:number | undefined;
    if (props.view !== "total") {
        switch (props.view) {
            case 'death':
                totalRank = userRank.deathRank;
                totalPercent = userRank.deathPercent;
                amount = userRank.death;
                break;
            case 'life':
                totalRank = userRank.lifeRank;
                totalPercent = userRank.lifePercent;
                amount = userRank.life;
                break;
            case 'fire':
                totalRank = userRank.fireRank;
                totalPercent = userRank.firePercent;
                amount = userRank.fire;
                break;
            case 'water':
                totalRank = userRank.waterRank;
                totalPercent = userRank.waterPercent;
                amount = userRank.water;
                break;
            case 'earth':
                totalRank = userRank.earthRank;
                totalPercent = userRank.earthPercent;
                amount = userRank.earth;
                break;
            case 'air':
                totalRank = userRank.airRank;
                totalPercent = userRank.airPercent;
                amount = userRank.air;
                break;
            case 'memory':
                totalRank = userRank.memoryRank;
                totalPercent = userRank.memoryPercent;
                amount = userRank.memory;
                break;
            case 'vision':
                totalRank = userRank.visionRank;
                totalPercent = userRank.visionPercent;
                amount = userRank.vision;
                break;
        }
    }

    function checkZero(number:number):JSX.Element|number {
        if (number === 0) {
            return <span className='unranked'>-</span>;
        } else {
            return number;
        }
    }

   

    return (
        <div className={'ranking-card ' + (props.view === 'totalRank' ? '' : 'is-element')}>
            <div className='total-content' onClick={() => setShowDetails(!showDetails)}>
                <div className='total-rank'>{checkZero(totalRank)}</div>
                <div className='rank-card-username'>{userRank.userName}</div>
                {props.view !== 'totalRank' && <div className='rank-amount'>{amount}</div>}
                <div className='rank-percent'>{totalPercent.toFixed(3)}%</div>
            </div>
            
            <div className='sub-content-wrapper' style={{height : (showDetails ? detailRef.current?.clientHeight : 0)}}>
                <div ref={detailRef} className='sub-content'>
                    {activeElements?.includes(1) && (userRank.death ? <div><span className='element-label'>Death</span> <span className='element-amount'>{userRank.death}</span> <span className='element-rank'>#{userRank.deathRank} {userRank.deathPercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Death</span> -</div>)}
                    {activeElements?.includes(2) && (userRank.life ? <div><span className='element-label'>Life</span> <span className='element-amount'>{userRank.life}</span> <span className='element-rank'>#{userRank.lifeRank} {userRank.lifePercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Life</span> -</div>)}
                    {activeElements?.includes(3) && (userRank.fire ? <div><span className='element-label'>Fire</span> <span className='element-amount'>{userRank.fire}</span> <span className='element-rank'>#{userRank.fireRank} {userRank.firePercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Fire</span> -</div>)}
                    {activeElements?.includes(4) && (userRank.water ? <div><span className='element-label'>Water</span> <span className='element-amount'>{userRank.water}</span> <span className='element-rank'>#{userRank.waterRank} {userRank.waterPercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Water</span> -</div>)}
                    {activeElements?.includes(5) && (userRank.earth ? <div><span className='element-label'>Earth</span> <span className='element-amount'>{userRank.earth}</span> <span className='element-rank'>#{userRank.earthRank} {userRank.earthPercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Earth</span> -</div>)}
                    {activeElements?.includes(6) && (userRank.air ? <div><span className='element-label'>Air</span> <span className='element-amount'>{userRank.air}</span> <span className='element-rank'>#{userRank.airRank} {userRank.airPercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Air</span> -</div>)}
                    {activeElements?.includes(7) && (userRank.memory ? <div><span className='element-label'>Memory</span> <span className='element-amount'>{userRank.memory}</span> <span className='element-rank'>#{userRank.memoryRank} {userRank.memoryPercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Memory</span> -</div>)}
                    {activeElements?.includes(8) && (userRank.vision ? <div><span className='element-label'>Vision</span> <span className='element-amount'>{userRank.vision}</span> <span className='element-rank'>#{userRank.visionRank} {userRank.visionPercent.toFixed(2)}%</span></div> : <div className='empty'><span className='element-label'>Vision</span> -</div>)}
                </div>
            </div>
        </div>
    )
}

export default RankingCard;