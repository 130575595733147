import axios from 'axios';
import React from 'react'
import { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";

const Viewer = (props:any) => {
    const {figureNumber, editionNumber} = useParams();
    const [imageKey, setImageKey] = React.useState<string>();
    const [loaded, setLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
      let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/support/figures/${figureNumber}/editions/${editionNumber}`;
      
      axios({
        "method": "GET",
        "url" : url
      })
      .then((response) => {
          setImageKey(response.data.imageKey);
       
        
      })
      .catch((error) => {
        console.log(error)
      })
    }, []);

  return (
    <div className="viewer">
        <Link className="close" to={"/"}>Close</Link>
        <img className={'img-lazy ' + (loaded ? 'show' : '')} onLoad={() => setLoaded(true)} src={`https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/${imageKey}/tokenfull`} />
                      
    </div>
  )
}

export default Viewer;
