import axios from 'axios';
import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import ResourceBalance from '../ResourceBalance';
import UserManager from '../UserManager';

import './ExperimentNav.scss';
interface IProps {
    
}

const ExperimentNav = (props:IProps) => {

  const context = useContext(AppContext);

  return (
    <div className="experiment-nav">
       <ul>
           <li><NavLink to='/' end>Home</NavLink></li>
           <li><NavLink  to='/experiment' end>Circle</NavLink></li>
           <li><NavLink to='/experiment/supply' end>Supply</NavLink></li>
           <li><NavLink  to='/experiment/world' end>World</NavLink></li>
           {context.auth && <li><NavLink to='/experiment/profile'>Profile</NavLink></li>}
           {context.auth && <li><NavLink to='/experiment/forge'>Forge</NavLink></li>}
       </ul>
       
    </div>
  )
}

export default ExperimentNav;