import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import Token from '../../../interfaces/Token';

import GalleryFigure from '../GalleryFigure';


import './FirstEpoch.scss';

interface IProps {

}

const FirstEpochV2 = (props: IProps) => {

  const [tokens, setTokens] = React.useState<Token[]>([]);

  React.useEffect(() => {
    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/support/epoch/1/cover-tokens`;
    
    axios({
      "method": "GET",
      "url" : url
    })
    .then((response) => {
        setTokens(response.data);
     
      
    })
    .catch((error) => {
      console.log(error)
    })
  }, []);

  function getTotalMints():number {
    let sum = 0;
    for (let i = 0; i < tokens.length; i++) {
        sum += tokens[i].totalEdition;
    }
    return sum;
  }

  return (
    <div className="first-epoch">
      <div className='part-1'>
          <div className='part-content'>
            <div className='intro-section'>
                <div className='epoch-dates'>2021/11/25 - 2021/12/21  {getTotalMints()} Mints</div>
              <h1 className='intro'>The First Epoch: <span className='sub-title'>Catch One Gold Crow</span></h1>
              <p>The clock is set, the longest night approaches, and the Crows&nbsp;take&nbsp;flight...</p>
            </div>
            
          </div>
          
        </div>
        <div className='epoch-gallery'>
              <ul>
                  {tokens.map((token) => 
                    <li key={token.figure}>
                        <GalleryFigure name={token.description} artist={token.artist} editions={token.totalEdition} imageKey={token.imageKey}  />
                    </li>
                  )}
                  
              </ul>
        </div>
    </div>
  )
}

export default FirstEpochV2;