import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Link, useParams, Outlet, useNavigate } from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import ModalButtonBar from '../../components/ModalButtonBar';
import ModalChoice from '../../components/ModalChoice';
import ModalContainer from '../../components/ModalContainer';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import FigureDetail from '../../interfaces/FigureDetail';
import GeneratedUsername from '../../interfaces/GeneratedUsername';
import User from '../../interfaces/User';
import './UserNameChangePage.scss';

interface IProps {

}

const UserNameChangePage = (props: IProps) => {

    let navigate = useNavigate();
    const context = React.useContext(AppContext);
    const session = context.userSession;
    const user: User = context.user || {} as User;

    const [names, setNames] = React.useState<GeneratedUsername[]>([]);

    const [locked, setLocked] = React.useState<GeneratedUsername[]>([]);

    const [selectedUsername, setSelectedUsername] = React.useState<GeneratedUsername>();

    const [error, setError] = React.useState<string>();

    const [saving, setSaving] = React.useState<boolean>(false);

    React.useEffect(() => {

        getAvailableNames();



    }, []);

    function getAvailableNames() {



        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getAvailableNames`;

        axios({
            "method": "GET",
            "url": url,
            "headers": {
                "signedMessage": session?.signature as string,
                "originalMessage": session?.message as string,
                "address": session?.address as string
            }
        })
            .then((response) => {
                let userNames: GeneratedUsername[] = response.data;
                setNames(userNames);
            })
            .catch((error) => {
                console.log(error)
            })
    }


    function takeUsername() {

        if (selectedUsername) {
            let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/setUsername`;
            setSaving(true);
            context.setShowLoader?.('Taking Undying Name', true);
            axios({
                "method": "POST",
                "url": url,
                "headers": {
                    "signedMessage": session?.signature as string,
                    "originalMessage": session?.message as string,
                    "address": session?.address as string,
                    'content-type': 'application/json'
                },
                "data": selectedUsername.id

            })
                .then((response) => {
                    let updatedUser:User = response.data;
                    context.setUser?.(updatedUser);
                    navigate("/experiment/profile", { replace: true });
                    setSaving(false);
                    context.setShowLoader?.('Taking Undying Name', false);
                })
                .catch((error) => {
                    console.log(error)
                    setError("There was a problem taking " + selectedUsername.userName + ", it may have already been claimed, please select another name.")
                    setSelectedUsername(undefined);
                    setSaving(false);
                    context.setShowLoader?.('Taking Undying Name', false);
                })
        }



    }

    function lockName(userName: GeneratedUsername) {
        let newLocked = locked;
        newLocked.push(userName);
        setLocked([...newLocked]);
    }

    function unlockName(userName: GeneratedUsername) {
        let newLocked = locked.filter((lockedName: GeneratedUsername) => lockedName.id != userName.id);

        setLocked([...newLocked]);
        if (!namesContains(userName)) {
            let newNames = names;
            newNames.push(userName);
            setNames([...newNames]);
        }
    }

    function lockedContains(username: GeneratedUsername): boolean {
        return locked.some(e => e.id === username.id);
    }

    function namesContains(username: GeneratedUsername): boolean {
        return names.some(e => e.id === username.id);
    }

    return (
        <div className="name-change-page fade-in">
            <div className='name-change-page-wrapper'>
                <h1>Choose an Undying Name</h1>
                <p>You shall forever be remembered as...</p>
                <p>Fetching new names will replace any unlocked names, locked names will be preserved between fetches. Once you take a name, no other can wear it.</p>
                {error && <div className='form-error'>{error}</div>}
                <div className='user-select-tools'>
                    <div><button onClick={getAvailableNames}>Fetch New Names</button></div>
                    <div><span>Locked names ({locked.length}/10)</span></div>

                </div>
                {(names.length > 0 || locked.length > 0) &&
                    <ul className='fade-in-fast'>
                        {locked.map((username: GeneratedUsername) =>
                            <li key={'user-' + username.id} className='username-locked'><span><button onClick={() => unlockName(username)}>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                                </svg>
                            </button></span><span className='user-name-option' onClick={() => setSelectedUsername(username)}>{username.userName}</span></li>
                        )}
                        {names.filter((username: GeneratedUsername) => !lockedContains(username)).map((username: GeneratedUsername) =>
                            <li key={'user-' + username.id} className='username-unlocked'><span><button className='user-save-button' disabled={locked.length >= 10} onClick={() => lockName(username)}>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path d="M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10A2,2 0 0,1 6,8H15V6A3,3 0 0,0 12,3A3,3 0 0,0 9,6H7A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17Z" />
                                </svg>
                            </button></span><span className='user-name-option' onClick={() => setSelectedUsername(username)}>{username.userName}</span></li>
                        )}
                    </ul>}


            </div>

            {selectedUsername && ReactDOM.createPortal(
                <ModalChoice onClose={() => setSelectedUsername(undefined)}>
                    <ModalContainer className={'approve-name-modal '+ (saving ? 'loading' : '')}>
                        <p>You are about to become</p>
                        <h2>{selectedUsername.userName}</h2>
                        <p>Once selected, this can not be&nbsp;changed.</p>
                        
                        <ModalButtonBar onApprove={takeUsername} onCancel={() => setSelectedUsername(undefined)} />
                        
                    </ModalContainer>
                </ModalChoice>
                , document.body)
            }

        </div>
    )
}

export default UserNameChangePage;