import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import TaskBoard from '../../../../interfaces/TaskBoard';
import TaskBoardSlot from '../../../../interfaces/TaskBoardSlot';
import FigureDisplay from '../../../FigureDisplay';
import BoardSlot from '../BoardSlot/BoardSlot';
import './CircleBloodPact.scss';


interface IProps {
    taskBoard:TaskBoard|undefined;
    selectTaskSlot(taskBoard:TaskBoard, slot:TaskBoardSlot):void;
    claimToken(tokenId:number, taskBoardId:number, slotId:number):void;
}

const CircleBloodPact = (props:IProps) => {
    const context = useContext(AppContext);
    const session = context.userSession;
    const taskBoard = props.taskBoard;

    const isUserInvolved = userInvolved();

    function userInvolved():boolean {
        let foundUser = false;
        taskBoard?.slots.forEach((slot:TaskBoardSlot) => 
            {if(slot.token && slot.token.userId == context.user?.id) {
                foundUser = true
            }}
        );
        return foundUser;
    }

return (
    <div className='circle-blood-pact'>
        <div className='circle-display'></div>
        <div className='circle-top'>
        {taskBoard && taskBoard.slots.filter((slot:TaskBoardSlot) => slot.boardLocation < 2).map((slot:TaskBoardSlot) => 
                                    <BoardSlot userInvolved={isUserInvolved} key={'slot' + slot.id} taskBoard={taskBoard} taskBoardSlot={slot} selectTaskSlot={props.selectTaskSlot} claimToken={props.claimToken} />
                                )}  
        </div>
        {taskBoard && taskBoard.slots.filter((slot:TaskBoardSlot) => slot.boardLocation >= 2).map((slot:TaskBoardSlot) => 
                                    <BoardSlot userInvolved={isUserInvolved} key={'slot' + slot.id} taskBoard={taskBoard} taskBoardSlot={slot} selectTaskSlot={props.selectTaskSlot} claimToken={props.claimToken} />
                                )}  
    </div>
  )
}

export default CircleBloodPact;