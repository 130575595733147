import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Token from '../../../interfaces/Token';
import GalleryFigure from '../GalleryFigure';


import './SecondEpoch.scss';


interface IProps {

}

const SecondEpoch = (props: IProps) => {

  const [tokens, setTokens] = React.useState<Token[]>([]);

  React.useEffect(() => {
    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/support/epoch/2/cover-tokens`;
    
    axios({
      "method": "GET",
      "url" : url
    })
    .then((response) => {
        setTokens(response.data);
     
      
    })
    .catch((error) => {
      console.log(error)
    })
  }, []);

  function getTotalMints():number {
    let sum = 0;
    for (let i = 0; i < tokens.length; i++) {
        sum += tokens[i].totalEdition;
    }
    return sum;
  }

  return (
    <div className="second-epoch">
      <div className='part-1'>
          <div className='part-content'>
            <div className='intro-section'>
                <div className='epoch-dates'>2021/12/21 - 2022/3/20  {getTotalMints()} Mints</div>
              <h1 className='intro'>The Second Epoch: <span className='sub-title'>Catch Five Gold Crow &amp; Harvest the Elements</span></h1>
              <p>Approach the circle and use what you have learned to begin channeling the elements. <Link to="/experiment">Participants, begin your&nbsp;experiment.</Link></p>
            </div>
          </div>
          
        </div>
        <div className='epoch-gallery'>
              <ul>
                  {tokens.map((token) => 
                    <li key={token.figure}>
                        <GalleryFigure name={token.description} artist={token.artist} editions={token.totalEdition} imageKey={token.imageKey}  />
                    </li>
                  )}
              </ul>
        </div>
    </div>
  )
}

export default SecondEpoch;