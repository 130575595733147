import './PageContent.scss';
interface IProps {
    children: JSX.Element | JSX.Element[] | string;
}

const PageContent = (props:IProps) => {

return (
    <div className="page-content">
      {props.children}
    </div>
  )
}

export default PageContent;