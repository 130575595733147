import axios from 'axios';
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';

import './ConnectPage.scss';
import { injected } from '../../connectors';
import App from '../../App';
import UserSession from '../../interfaces/UserSession';


declare var window: any;



interface IProps {
  
}






const ConnectPage = (props:IProps) => {
  //const context = useContext(AppContext);
  const web3 = new Web3(window.ethereum);
  const {active, account, library, connector, activate, deactivate} = useWeb3React();
  const [userSession, setUserSession] = React.useState<UserSession>({} as UserSession);
  const [signed, setSigned] = React.useState<boolean>(false);
  const [storedAccounts, setStoredAccounts] = React.useState<Map<string, UserSession>>(getDefaultStateMap('storedAccounts'));


  
  function getDefaultStateMap(key:string):Map<string, any> {
    let rawMap = localStorage.getItem(key);
    if(rawMap) {
      return new Map(JSON.parse(rawMap));
    } else {
      return new Map();
    }
    
  }

  function updateStoredAccounts(account:string, userSession:UserSession) {
    storedAccounts.set(account, userSession);
    //formData.set(key, value);
    setStoredAccounts(new Map(storedAccounts));
    localStorage.setItem("storedAccounts", JSON.stringify(Array.from(storedAccounts.entries())));
    
  }


  async function connect() {
    if (typeof window.ethereum !== 'undefined') {
      // console.log('MetaMask is installed!');
    }
    
    try {
      await activate(injected);
     
      
    } catch(ex) {
      console.log(ex);
    }
  }
 
  useEffect(() => {
    if(window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        
        window.location.reload();
      });
      window.ethereum.on('accountsChanged', () => {
        
        window.location.reload();
      });
  }
});


  useEffect(() => {
      
    //connect();
  
    // window.ethereum.on('accountsChanged', function (accounts:any) {
    //   // Time to reload your interface with accounts[0]!
    //   console.log(accounts);
    // })
  
    // window.ethereum?.on('accountsChanged', function (accounts:any) {
    //   // Time to reload your interface with accounts[0]!
    //   //console.log(accounts);
    //   let newAccount:string = accounts[0] as string;
    //   let userSession = storedAccounts.get(newAccount);

    //   if(userSession) {
    //     setUserSession(userSession);
    //   } else {
    //     connect();
    //   }
      
    //   // let storedSig = localStorage.getItem(accounts[0] + "-sig");
    //   // if (storedSig) {
    //   //   setSignature(accounts[0],storedSig);
    //   // } else {
  
    //   // }
      
    // })
  
    window.ethereum?.on('connect', function (accounts:any) {
      // Time to reload your interface with accounts[0]!
      //console.log(accounts);
      let newAccount:string = accounts[0] as string;
      let userSession = storedAccounts.get(newAccount);

      if(userSession) {

        setUserSession(userSession);
      } else {

        connect();
      }
      
      // let storedSig = localStorage.getItem(accounts[0] + "-sig");
      // if (storedSig) {
      //   setSignature(accounts[0],storedSig);
      // } else {
  
      // }
      
    })

    window.ethereum?.on('disconnect', function (accounts:any) {
      // Time to reload your interface with accounts[0]!
      //console.log(accounts);
      let newAccount:string = accounts[0] as string;
      //let userSession = storedAccounts.get(newAccount);
     // localStorage.setItem("storedAccounts", JSON.stringify([]));

      localStorage.clear();
      setUserSession({} as UserSession);
      
      // let storedSig = localStorage.getItem(accounts[0] + "-sig");
      // if (storedSig) {
      //   setSignature(accounts[0],storedSig);
      // } else {
  
      // }
      
    })

    
  }, []);

  useEffect(() => {
    //correct
    

      if (account) {
        
        let userSession = storedAccounts.get(account);
        if(!userSession) {
          web3.eth.personal.sign(web3.utils.toHex("GOLDCROW"), account, '',function(err:Error, 
            signature:string) {
                  let originalMessageHash = web3.utils.toHex("GOLDCROW");
                  //context.setSignature?.(signature);
                  //context.setOriginalMessage?.(originalMessageHash);
                  
                  //let url = "http://localhost:8080/api/auth";
                  let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/auth`;  
                    axios({
                      "method": "GET",
                      "url" : url,
                      "headers" : {
                        "signedMessage" : signature,
                        "originalMessage": originalMessageHash,
                        "address": account
                      }
                    })
                    .then((response) => {
                      let authUser = response.data;
                      //console.log(authUser);
                      let newUserSession = {} as UserSession;
                      newUserSession.user = authUser;
                      newUserSession.address = account;
                      newUserSession.signature = signature;
                      newUserSession.message = originalMessageHash;
                      updateStoredAccounts(account, newUserSession);
                      setUserSession(newUserSession);
                      //  context.setAuthUser?.(authUser);
                      //  context.setAuth?.(true);
                      //  context.setOriginalMessage?.(originalMessageHash);
                      //  context.setSignature?.(signature);
                      //  context.setAccount?.(account);
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                   
                  
                  //axios.get("http://localhost:8080/api/auth", {headers: {"signedMessage":signature,"originalMessage": originalMessageHash, "address": account }});
            });
        } else {
          setUserSession(userSession);
        }
        
      }
      
    
  }, [account]);


    
  return (
    <div key={"key-" + userSession.address + userSession.signature + userSession.user?.id } className="connect connect-page">
        <App connect={connect} userSession={userSession} />
    </div>
  )
}

export default ConnectPage;

