import React from 'react';
import { useState, useEffect } from 'react';

interface IProps {
    initialSeconds:number;
    onTimerEnd():void;
}

const Timer = (props:IProps) => {

    const [secondsLeft, setSecondsLeft] = React.useState(props.initialSeconds);
  
    React.useEffect(() => {
      const interval = setInterval(() => {
        if (secondsLeft == 0) {
          clearInterval(interval);
          props.onTimerEnd();
        } else {
          setSecondsLeft(secondsLeft - 1)
        }
      }, 1000)
      return () => clearInterval(interval)
    }, [secondsLeft])

    return (
        <div>
            {secondsLeft}
        </div>
    )
}

export default Timer;