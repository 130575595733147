import axios from 'axios';
import React from 'react'

import { AppContext } from '../../AppContext';
import MintableList from '../../components/MintableList';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';
import PageNav from '../../components/PageNav';
import PageWrapper from '../../components/PageWrapper';
import ResearchMasteryLevel from '../../interfaces/ElementMasteryLevel';
import ResearchMastery from '../../interfaces/ElementMastery';
import User from '../../interfaces/User';
import './MasteryPage.scss';
import ElementMastery from '../../interfaces/ElementMastery';
import ElementMasteryLevel from '../../interfaces/ElementMasteryLevel';
import MasteryBar from '../../components/MasteryBar';

interface IProps {
  
}

const MasteryPage = (props:IProps) => {

  const context = React.useContext(AppContext);
  const session = context.userSession;
  const user = session?.user || {} as User;
  
  const [elementMasteryList, setElementMasteryList] = React.useState<ElementMastery[]>([]);

  React.useEffect(() => {

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/mastery`;
    //context.setShowLoader?.('Loading Task', true);
    axios({
        "method": "GET",
        "url": url,
        "headers": {
          "signedMessage": session?.signature as string,
          "originalMessage": session?.message as string,
          "address": session?.address as string
        }
      })
      .then((response) => {
        let elementMasteryList:ElementMastery[] = response.data;
        setElementMasteryList(elementMasteryList);
        
        //context.setShowLoader?.('Loading Tasks', false);
      })
      .catch((error) => {
        console.log(error);
        //context.setShowLoader?.('Loading Tasks', false);
      })

  }, []);

  function researchNextLevel(masteryLevel:ElementMasteryLevel) {

    if (masteryLevel) {
        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/mastery/research`;
        //setSaving(true);
        context.setShowLoader?.('Beginning Research', true);
        axios({
            "method": "POST",
            "url": url,
            "headers": {
                "signedMessage": session?.signature as string,
                "originalMessage": session?.message as string,
                "address": session?.address as string,
                'content-type': 'application/json'
            },
            "data": masteryLevel.id

        })
            .then((response) => {
              let elementMasteryList:ElementMastery[] = response.data;
              setElementMasteryList(elementMasteryList);
                context.setShowLoader?.('Beginning Research', false);
            })
            .catch((error) => {
                console.log(error)
               
                context.setShowLoader?.('Beginning Research', false);
            })
    }



}

  return (
    <div className="mastery-page fade-in">
        <PageWrapper>
        <PageHeader>Research</PageHeader>
        <PageNav children={undefined}></PageNav>
        <PageContent>
            <div>
                {elementMasteryList.map((elementMastery:ElementMastery) => 
                    <div className='mastery-section'>
                    <MasteryBar onResearchNextLevel={researchNextLevel} elementId={elementMastery.elementId} masteryLevel={elementMastery.nextLevel} />
                    
{/*                         
                        {getAllExp(level.expCost).map((exp:number, index:number) => 
                            <div>{exp} {level.userMasteryExp[index]}</div>
                        )} */}
                    </div>
                )}
            </div>
        </PageContent>
      </PageWrapper>
    </div>
  )
}

export default MasteryPage;