import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Web3 from 'web3';

import { AppContext } from '../../AppContext';
import MintableToken from '../../interfaces/MintableTokens';
import MintableItem from './MintableItem';

import './MintableList.scss';

declare var window: any;

interface IProps {

}

const MintableList = (props: IProps) => {

    const context = React.useContext(AppContext);
    const session = context.userSession;
    const web3 = new Web3(window.ethereum);
    const [mintableTokens, setMintableTokens] = React.useState<MintableToken[]>([]);
    const [balance, setBalance] = React.useState<number>(-1);
    const [chain, setChain] = React.useState<number>();


    React.useEffect(() => {
      if (context.auth) {
        context.setShowLoader?.(`Loading Figures`, true);
      let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getMintable`;
      
      updateBalance();

      axios({
          "method": "GET",
          "url": url,
          "headers": {
              "signedMessage": session?.signature as string,
              "originalMessage": session?.message as string,
              "address": session?.address as string
          }
      })
          .then((response) => {
              let tokens: MintableToken[] = response.data;
              setMintableTokens(tokens);
              context.setShowLoader?.(`Loading Figures`, false);
          })
          .catch((error) => {
              console.log(error);
              context.setShowLoader?.(`Loading Figures`, false);
          })
  
  
        }
  
    }, []);

    async function updateBalance() {
        if(session?.address) {
            let chainId = await web3.eth.getChainId();
            setChain(chainId);
            let balance = await web3.eth.getBalance(session?.address);
            let fromWei = web3.utils.fromWei(balance, 'ether');
            setBalance(Number(fromWei));
          }
    }

    return (
        <div className='mintable-list fade-in'>
            {chain && Boolean(balance >= 0) &&
            <ul>
                {mintableTokens.filter((a:MintableToken) => a.status !== 'minted' && a.status !== 'expired').sort((a:MintableToken, b:MintableToken) => (new Date(b.createDate).getTime() - new Date(a.createDate).getTime())).map((token: MintableToken) =>
                    <li key={token.id}>
                        <MintableItem chainId={chain} balance={balance} token={token} updateTokenList={setMintableTokens} />
                    </li>
                )}
                {mintableTokens.filter((a:MintableToken) => a.status === 'minted' || a.status === 'expired').sort((a:MintableToken, b:MintableToken) => (new Date(b.mintDate).getTime() - new Date(a.mintDate).getTime())).map((token: MintableToken) =>
                    <li key={token.id}>
                        <MintableItem chainId={chain}  balance={balance} token={token} updateTokenList={setMintableTokens} />
                    </li>
                )}
            </ul>}
        </div>
    )
}

export default MintableList;