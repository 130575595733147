import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import {Link, useParams, Outlet} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import InventoryWrapper from '../../components/InventoryWrapper';
import ModalButtonBar from '../../components/ModalButtonBar';
import ModalChoice from '../../components/ModalChoice';
import ModalContainer from '../../components/ModalContainer';
import ResourceBalance from '../../components/ResourceBalance';
import SlotFigureOptions from '../../components/SlotFigureOptions';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import ExpUserBoardSlot from '../../interfaces/ExpUserBoardSlot';
import FigureDetail from '../../interfaces/FigureDetail';
import Slot from '../../interfaces/Slot';
import './UserPage.scss';

interface IProps {
  balances:Balances;
}

const UserPage = (props:IProps) => {

  const context = React.useContext(AppContext);
  
  const userBoard = context.userBoard;
  const activeSlots = userBoard?.slots.filter((slot:ExpUserBoardSlot) => slot.unlocked) || [];
  const [selectedSlot, setSelectedSlot] = React.useState<ExpUserBoardSlot|undefined>();
  const [showBoardOptions, setShowBoardOptions] = React.useState<boolean>(false);

  function prevSlot():void {
    let newSlot = selectedSlot!.boardLocation - 1;
    if (newSlot < 0) {
      newSlot = activeSlots.length - 1;
    }
    setSelectedSlot(activeSlots[newSlot]);
  }

  function nextSlot():void {
    let newSlot = selectedSlot!.boardLocation + 1;
    if (newSlot > activeSlots.length - 1) {
      newSlot = 0;
    }
    setSelectedSlot(activeSlots[newSlot]);
  }

  function upgradeBoard():void {

  }

  return (
    <div className="user-page fade-in">
      
      {context.auth &&  <><ResourceBalance balances={props.balances} /><Vial balances={props.balances} /></>}
       {context.auth && userBoard && <Circle userBoard={userBoard} selectedSlot={selectedSlot} onSlotClick={setSelectedSlot}  />}
       {!context.auth &&
       <>
         <div className='circle-connect'><img src='/circle-connect.png' /></div>
        <button className='connect-area' onClick={context.connect}></button>
        </>
      }
      {selectedSlot && userBoard && <SlotFigureOptions prevSlot={prevSlot} nextSlot={nextSlot} userBoard={userBoard} onClose={() => setSelectedSlot(undefined)} slot={selectedSlot} />}
      {context.auth && <div>
          <div className='circle-options'><button onClick={() => setShowBoardOptions(true)}>The Axis</button></div>
        </div>}
        {showBoardOptions && ReactDOM.createPortal(
                <ModalChoice onClose={() => setShowBoardOptions(false)}>
                    <ModalContainer className='supply-minting-modal' >

                        <h2>The Axis</h2>
                        <div className='upgrade-circle-details'>
                        <img src="/circle4.png" />
                        </div>
                        <div className='circle-prices item-details'>
                          <h2>Draw a 4 Slot Circle</h2>
                      <div className='price-option'>
                      <div className='check-box'></div>
                        <ul>
                    
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-3'}></span> 2500 Artifact (Fire)</li>
                    
                    </ul>
                    </div>
                    <div className='price-option'>
                      <div className='check-box'></div>
                    <ul>
                    
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-4'}></span> 2500 Humor (Water)</li>
                    
                    </ul>
                    </div>
                    <div className='price-option'>
                    <div className='check-box'></div>
                    <ul>
                    
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-5'}></span> 2500 Locus (Earth)</li>
                    
                    </ul>
                    </div>
                    <div className='price-option'>
                    <div className='check-box'></div>
                    <ul>
                    
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-6'}></span> 2500 Craft (Air)</li>
                    
                    </ul>
                    </div>
                    <div className='price-option'>
                    <div className='check-box'></div>
                    <ul>
                    
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-3'}></span> 525 Artifact (Fire)</li>
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-4'}></span> 525 Humor (Water)</li>
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-5'}></span> 525 Locus (Earth)</li>
                      <li  className={(true ? '' : ' not-enough')}><span className={'element-type element-6'}></span> 525 Craft (Air)</li>
                    </ul>
                    </div>
                    </div>
                    
                        <ModalButtonBar approveDisabled={true} onApprove={upgradeBoard} approveLabel="Upgrade" onCancel={() => setShowBoardOptions(false)} />

                    </ModalContainer>
                </ModalChoice>
                , document.body)
            }
    </div>
  )
}

export default UserPage;