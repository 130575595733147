import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import TaskHistory from '../../interfaces/TaskHistory';
import UserTaskRank from '../../interfaces/UserTaskRank';

import './HistoryCard.scss';
interface IProps {
    taskHistory:TaskHistory;
}

const HistoryCard = (props: IProps) => {

    const context = useContext(AppContext);
    const taskHistory = props.taskHistory;

    const [showDetails, setShowDetails] = React.useState<boolean>(false);
    const detailRef = React.useRef<HTMLDivElement>(null);
   
    

    function checkZero(number:number):JSX.Element|number {
        if (number === 0) {
            return <span className='unranked'>-</span>;
        } else {
            return number;
        }
    }

    function getElementName(elementId:number):string {
        switch (elementId) {
          case 1:
            return 'Death';
          case 2:
            return 'Life';
          case 3:
            return 'Artifact (Fire)';
          case 4:
            return 'Humor (Water)';
          case 5:
            return 'Locus (Earth)';
          case 6:
            return 'Craft (Air)';
          case 7:
            return 'Memory (Past)';
          case 8:
            return 'Vision (Future)';
          
          }
          return 'Death';
      }

      function getFormattedDate(date:Date) {
        var month = ("0" + (date.getMonth() + 1)).slice(-2);
        var day  = ("0" + (date.getDate())).slice(-2);
        var year = date.getFullYear();
        var hour =  ("0" + (date.getHours())).slice(-2);
        var min =  ("0" + (date.getMinutes())).slice(-2);
        var seg = ("0" + (date.getSeconds())).slice(-2);
        return year + "/" + month + "/" + day + " " + hour + ":" +  min + ":" + seg;
    }

    return (
        <div className={'history-card '}>
            <div className='history-card-heading'>
                <div className='history-date'>{getFormattedDate(new Date(taskHistory.timestamp))}</div>
                <div className='history-user'>{taskHistory.userName}</div>
            </div>
            <div className='history-card-detail'>
                <div className='history-type'>Sacrifice</div>
                <div className='history-element'>{getElementName(taskHistory.elementId)}</div>
                <div className='history-amount'>{taskHistory.amount}</div>
            </div>
        </div>
    )
}

export default HistoryCard;
