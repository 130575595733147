import axios from 'axios';
import React, { useContext, useRef } from 'react'
import { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import './Circle.scss';
import {ItemTypes} from '../../Constants';
import FigureDetail from '../../interfaces/FigureDetail';
import Figure from '../Figure';
import { AppContext } from '../../AppContext';
import ExpandingCircle from '../ExpandingCircle';
import Slot from '../../interfaces/Slot';
import FigureDisplay from '../FigureDisplay';
import ExpUserBoard from '../../interfaces/ExpUserBoard';
import ExpUserBoardSlot from '../../interfaces/ExpUserBoardSlot';

interface IProps {
  selectedSlot:ExpUserBoardSlot|undefined;
  onSlotClick(slot:ExpUserBoardSlot):void;
  userBoard:ExpUserBoard;
}


const Circle = (props:IProps) => {

  const appContext = useContext(AppContext);
  const balances = appContext.balances;
  const board = appContext.board || [];
  const activeSlots = props.userBoard.slots.filter((slot:ExpUserBoardSlot) => slot.unlocked);
  const slots = activeSlots.length;
    const size = useWindowSize();
    const circleRef = React.useRef<HTMLDivElement>(null);
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    //const [boardElements, setBoardElements] = useState<{color:string}[]>(getBoardElements(board));
    //const [boardSlots, setBoardSlots] = useState<number[]>(getBoardSlots(board));
    const [cameraPosition, setCameraPosition] = useState<{x:number, y:number}>({x:0, y:0});
    const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(
      null
    );

    const [points, setPoints] = React.useState<{x:number, y:number}[]>([]);
    const [figureSize, setFigureSize] = React.useState<number>(0);
    
    // React.useEffect(() => {
    //   setBoardSlots(getBoardSlots(board));
    //   setBoardElements(getBoardElements(board));
    // }, [board]);


    function onSlotClick(slot:ExpUserBoardSlot, x:number, y:number) {
      props.onSlotClick(slot);
      setCameraPosition({x: x , y: y });
    }

function getElementProgress(element:number):number {
  if (element === 1) {
    return  getFraction(balances?.death || 0);
  }
  if (element === 2) {
    return  getFraction(balances?.life || 0);
  }
  if (element === 3) {
    return  getFraction(balances?.fire || 0);
  }
  if (element === 4) {
    return  getFraction(balances?.water || 0);
  }
  if (element === 5) {
    return  getFraction(balances?.earth || 0);
  }
  if (element === 6) {
    return  getFraction(balances?.air || 0);
  }
  if (element === 7) {
    return  getFraction(balances?.memory || 0);
  }
  if (element === 8) {
    return  getFraction(balances?.vision || 0);
  }
  return 0;
}
  
function getBoardProgress():number[] {
  let slotProgress:number[] = [];
  for (let i = 0; i < activeSlots.length; i++) {
    slotProgress.push(getElementProgress(activeSlots[i].token?.elementId || 0));
  }
  return slotProgress;
}

function getBoardElements():{color:string}[] {
  let colorArray:{color:string}[] = [];
  for (let i = 0; i < activeSlots.length; i++) {
    colorArray.push(getColorForElement(activeSlots[i].token?.elementId || 0));
  }
  return colorArray;
}

function getBoardSlots():number[] {
  let boardSlots:number[] = [];
  for (let i = 0; i < activeSlots.length; i++) {
    boardSlots.push(activeSlots[i].token ? 1 : 0);
  }
  return boardSlots;
}

function getColorForElement(elementId:number):{color:string} {

    switch (elementId) {
      case 1:
        return {color: 'rgba(0, 0, 0, 0.1)'};
      case 2:
        return {color: 'rgba(255, 255, 255, 0.1)'};
      case 3:
        return {color: 'rgba(182, 0, 0, 0.1)'};
      case 4:
        return {color: 'rgba(56, 63, 163, 0.1)'};
      case 5:
        return {color: 'rgba(13, 80, 22, 0.1)'};
      case 6:
        return {color: 'rgba(122, 122, 122, 0.1)'};
      case 7:
        return {color: 'rgba(182, 74, 2, 0.1)'};
      case 8:
        return {color: 'rgba(73, 7, 85, 0.1)'};
      
      }
      return {color: 'rgba(0, 0, 0, 0)'};
  
}

function getFraction(n:number):number {
  return  n - Math.floor(n);
}

  return (
    <div>
      
    <div className="circle" ref={circleRef}>
      
      
      <ExpandingCircle setFigureSize={setFigureSize} cameraPosition={cameraPosition} selectedPoint={props.selectedSlot?.boardLocation} setPoints={setPoints} progress={getBoardProgress()} elementColors={getBoardElements()} points={slots} isSlotted={getBoardSlots()} />
      
     
    </div>
    <div className={'circle-channels points-' + slots}>
      {Boolean(points.length > 0) && points.map((point:{x:number, y:number}, index:number) => 
      <div className={'slot-wrapper slot-' + (index + 1)} style={{width : figureSize, height: figureSize, top: point.y, left: point.x}} >
        <div onClick={() => onSlotClick(activeSlots[index], point.x, point.y)} className={"circle-channel " + (activeSlots[index].token ? 'in' : '')}>
        <FigureDisplay token={activeSlots[index].token} size={'half'} />
        
      </div>
      </div>
      )}
      </div>
    </div>
  )
}

export default Circle;


// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: 0 ,
      height: 0,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        if (window && window.innerWidth && window.innerHeight) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
        }
        
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }