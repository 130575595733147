import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';

import './ModalChoice.scss';
interface IProps {
    children: JSX.Element | JSX.Element[];
    onClose?():void;
}

const ModalChoice = (props:IProps) => {

  const context = useContext(AppContext);

  useEffect(() => {
    const handleEsc = (event:KeyboardEvent) => {
       if (event.key === 'Escape' && props.onClose) {
        props.onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  
  return (
    <div className="modal-choice fade-in-fast">
        
        <div className='modal-screen' onClick={props.onClose}></div>
        <div className='modal-content'>
        {props.children}</div>
    </div>
  )
}

export default ModalChoice;