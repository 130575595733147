import axios from 'axios';
import { connect } from 'http2';
import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { AppContext } from '../../../AppContext';

import FigureDetail from '../../../components/FigureDetail';
import FigureDisplay from '../../../components/FigureDisplay';
import ModalButtonBar from '../../../components/ModalButtonBar';
import ModalChoice from '../../../components/ModalChoice';
import ModalContainer from '../../../components/ModalContainer';

import CostItem from '../../../interfaces/CostItem';
import SupplyToken from '../../../interfaces/SupplyToken';

import User from '../../../interfaces/User';
import './SupplyItem.scss';

interface IProps {
  token:SupplyToken;
  scrollPosition:number;
  onClaim(supplyTokenId:number):void;
  claimPending:boolean;
  filter:string;
}

const SupplyItem = (props:IProps) => {

    const context = React.useContext(AppContext);
    const session = context.userSession;
    const user = session?.user || {} as User;
    const token = props.token;
    const userCanAfford = canAffordAll(token.costItems);
    const compRef = React.useRef<HTMLDivElement>(null);

    const [imageVisible, setImageVisible] = React.useState<boolean>(false);
    const [pending, setPending] = React.useState<boolean>(false);
    

    React.useEffect(() => {
      
      if(!imageVisible) {
        let topPosition = compRef.current?.getBoundingClientRect().top;
        if (topPosition != undefined && props.scrollPosition >= topPosition) {
          setImageVisible(true);
        }
        
      }
    }, [props.scrollPosition, props.filter]);





      function canAfford(item:CostItem):boolean {
        switch (item.elementId) {
          case 1:
            return item.amount <= (context.balances?.death || 0);
          case 2:
            return item.amount <= (context.balances?.life || 0);
          case 3:
            return item.amount <= (context.balances?.fire || 0);
          case 4:
            return item.amount <= (context.balances?.water || 0);
          case 5:
            return item.amount <= (context.balances?.earth || 0);
          case 6:
            return item.amount <= (context.balances?.air || 0);
          case 7:
            return item.amount <= (context.balances?.memory || 0);
          case 8:
            return item.amount <= (context.balances?.vision || 0);
          
          }
        return false;
      }

      function canAffordAll(items:CostItem[]):boolean {

        for (let i = 0; i < items.length; i++) {
          let canAffordItem = canAfford(items[i]);
          if(!canAffordItem) {
            return false;
          }
        }
        return true;
      }

      function claimToken(tokenId:number) {
        //props.onClaim(tokenId);
        setPending(true);
      }

      function finalizeClaim() {
        props.onClaim(token.id);
      }

  return (
    <div ref={compRef} className={'supply-token fade-in-fast ' + (userCanAfford ? 'can-afford' : 'cant-afford')}>
                    <FigureDisplay isVisible={imageVisible} token={token.token} size="half" />
                    <FigureDetail figure={token.token} />
                    <div className='item-details'>
                    <span className={'percent-tag p' + token.pricePercentage}>{token.pricePercentage}%</span>
                    <ul>
                    {token.costItems.map((item:CostItem) => 
                      <li key={item.elementId} className={(canAfford(item) ? '' : ' not-enough')}><span className={'element-type element-' + item.elementId}></span> {item.amount} {item.name}</li>
                    )}
                    </ul>
                    </div>
                    <div className='mint-area'>
                                <div className={'total ' + (Number(token.mintCost) == 0 ? 'no-cost' : '')}><img src='/bsc_logo.png' />{token.mintCost}</div>
                                <div>
                                <button className={'mint-button ' + (!userCanAfford ? 'expired-mint-button' : '')} onClick={() => claimToken(token.id)} disabled={!userCanAfford || props.claimPending}>Claim</button>
                                
                                </div>
                            </div>
                            {pending && ReactDOM.createPortal(
                <ModalChoice onClose={() => setPending(false)}>
                    <ModalContainer className='supply-minting-modal' >

                        <h2>Claim {token.token.name} {token.token.edition}/{token.token.totalEditions}</h2>
                        <FigureDisplay token={token.token} size='half' />
                        <FigureDetail figure={token.token} />
                        <div className='item-details'>
                    <span className={'percent-tag p' + token.pricePercentage}>{token.pricePercentage}%</span>
                    <ul>
                    {token.costItems.map((item:CostItem) => 
                      <li key={item.elementId} className={(canAfford(item) ? '' : ' not-enough')}><span className={'element-type element-' + item.elementId}></span> {item.amount} {item.name}</li>
                    )}
                    </ul>
                    </div>
                    <div className='mint-area'>
                                <div className={'total ' + (Number(token.mintCost) == 0 ? 'no-cost' : '')}><img src='/bsc_logo.png' />{token.mintCost}</div>
                                
                            </div>
                        <ModalButtonBar approveDisabled={!userCanAfford} onApprove={finalizeClaim} onCancel={() => setPending(false)} />

                    </ModalContainer>
                </ModalChoice>
                , document.body)
            }
                </div>
  )
}

export default SupplyItem;
