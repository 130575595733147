
import { createContext } from "react";
import Balances from "./interfaces/Balances";
import ExpUserBoard from "./interfaces/ExpUserBoard";
import ExpUserBoardSlot from "./interfaces/ExpUserBoardSlot";
import FigureDetail from "./interfaces/FigureDetail";
import User from "./interfaces/User";
import UserBoard from "./interfaces/UserBoard";
import UserSession from "./interfaces/UserSession";


export interface AppContextData {
    auth:boolean;
    user:User;
    userFigureMap:Map<number, FigureDetail>;
    dragItem:FigureDetail;
    userFigures:FigureDetail[];
    board:(FigureDetail|undefined)[];
    balances:Balances;
    userSession:UserSession;
    userBoard:ExpUserBoard;
    updateUserFigures():void;
    placeFigureOnUserBoard(slot:ExpUserBoardSlot, figure:FigureDetail):void;
    removeFigureFromUserBoard(figure:FigureDetail):void;
    updateUserBoard(updatedBoard:ExpUserBoard):void;
    setDragItem(figure:FigureDetail|undefined, offsetX:number, offsetY:number):void;
    setUserBalance(balance:Balances):void;
    setUser(user:User):void;
    swap(newPosition:number, dragItem:FigureDetail, remove?:boolean):void;
    setShowLoader(message:string, show:boolean):void;
    updateBoard(updatedBoard:UserBoard[]):void;
    // setOriginalMessage(string:string):void;
    // setAuth(auth:boolean):void;
    // setAccount(account:string):void;
    // setAuthUser(user:User):void;
    connect():void;
  }
   

   
  export const AppContext = createContext<Partial<AppContextData>>({});
