import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import FigureDisplay from '../../../components/FigureDisplay';
import './GalleryFigure.scss';

interface IProps {
    name:string;
  artist:string;
  editions:number;
  imageKey:string;
}

const GalleryFigure = (props:IProps) => {

    //const [loaded, setLoaded] = React.useState<boolean>(false);

    function replaceWidow(str:string):string {
        if (str.split(' ').length > 3) {
            var pos = str.lastIndexOf(' ');
            str = str.substring(0,pos) + '\u00a0' + str.substring(pos+1);
            
        }
        return str;
    }


  return (
      <>
    <div className='gallery-figure-image'>
                          <FigureDisplay imageKey={props.imageKey} size='half' />
                      </div>
                      <div className='gallery-figure-detail'>
                        <h2>{replaceWidow(props.name)}</h2>
                        <span className='total-editions'>{props.editions}</span>
                        <span className='artist'>{props.artist}</span>
        </div>
        </>
  )
}

export default GalleryFigure;