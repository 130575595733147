import axios from 'axios';
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Balances from '../../interfaces/Balances';
import FigureDetail from '../../interfaces/FigureDetail';
import Figure from '../Figure';
import Vial from '../Vial';
import ElementBalance from './ElementBalance';
import './ResourceBalance.scss';
interface IProps {
    balances:Balances|undefined;
}

const ResourceBalance = (props:IProps) => {
  const balances = props.balances || {} as Balances;
  
  const [activeElements, setActiveElements] = React.useState<number[]>([]);

  const context = useContext(AppContext);

  React.useEffect(() => {
  

    
     
    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/world/settings`;
    axios({
      "method": "GET",
      "url" : url
    })
    .then((response) => {
      setActiveElements(response.data.activeElements);
    })
    .catch((error) => {
      console.log(error);
    })
 
 }, []);



  return (
    <div className={"resource-balance " + (activeElements.length > 0 ? 'active': '')}>
      <ElementBalance active={activeElements.includes(1)} elementId={1} balance={balances.death} />
      <ElementBalance active={activeElements.includes(2)} elementId={2} balance={balances.life} />
      <ElementBalance active={activeElements.includes(3)} elementId={3} balance={balances.fire} />
      <ElementBalance active={activeElements.includes(4)} elementId={4} balance={balances.water} />
      <ElementBalance active={activeElements.includes(5)} elementId={5} balance={balances.earth} />
      <ElementBalance active={activeElements.includes(6)} elementId={6} balance={balances.air} />
      <ElementBalance active={activeElements.includes(7)} elementId={7} balance={balances.memory} />
      <ElementBalance active={activeElements.includes(8)} elementId={8} balance={balances.vision} />
    </div>
  )
}

export default ResourceBalance;