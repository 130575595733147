import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import {Link, useParams, Outlet} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import MintableList from '../../components/MintableList';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import FigureDetail from '../../interfaces/FigureDetail';
import MintableToken from '../../interfaces/MintableTokens';
import User from '../../interfaces/User';
import './ProfilePage.scss';

interface IProps {
  
}

const ProfilePage = (props:IProps) => {

  const context = React.useContext(AppContext);
  const session = context.userSession;
  const user = context.user || {} as User;





  

  return (
    <div className="profile-page fade-in">
      <div className='user-header'>
        <h1>{user.name}</h1>
        <h2>{user.address}</h2>
      </div>
      {!user.usernameChanged &&
      <div className='prompt-box'>
        <h2>Your Undying Name</h2>
        <p>What should I call you? Perhaps <strong>{user.name}</strong>? If you find this fitting you may keep it, or <Link to="/experiment/profile/change-name">choose your own</Link>...</p>
      </div>
      }
    </div>
  )
}

export default ProfilePage;