import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import {Link, useParams, Outlet} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import GlobalVials from '../../components/Quest/GlobalVials';

import WorldBoard from '../../components/Quest/WorldBoard/WorldBoard';

import ResourceBalance from '../../components/ResourceBalance';

import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import FigureDetail from '../../interfaces/FigureDetail';
import Task from '../../interfaces/Task';
import './WorldPage.scss';

interface IProps {
}

const WorldPage = (props:IProps) => {

  const context = React.useContext(AppContext);
  const [tasks, _setTasks] = React.useState<Task[]>([]);
  const tasksRef = React.useRef(tasks);
  var eventSource: EventSource | undefined;

  function setTasks(tasks:Task[]) {
    tasksRef.current = tasks;
    _setTasks(tasks);
  }

  function updateTask(task:Task):void {
    
    let updatedTasks = tasksRef.current;
    //console.log(updatedTasks);
    for (let i = 0; i < updatedTasks.length; i++) {
      if(updatedTasks[i].id === task.id) {
        updatedTasks[i] = task;
        break;
      }
    }
    //console.log(updatedTasks);
    setTasks([... updatedTasks]);
  }

  React.useEffect(() => {
    

    eventSource = new EventSource(`${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/events`);
    eventSource.addEventListener("TaskUpdate", (e:any) => {
      updateTask(JSON.parse(e.data))
    });
    //eventSource.onmessage = e => updateTask(JSON.parse(e.data));
    eventSource.onopen = (e) => {console.log("connection opened")}
    //console.log(taskBalances);
    
  
  return () => {
    eventSource?.close();
    
  }


 
}, [])


  React.useEffect(() => {
  
     let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks`;
     context.setShowLoader?.('Loading Tasks', true);
     axios({
       "method": "GET",
       "url" : url
     })
     .then((response) => {
       let tasks:Task[] = response.data;
       setTasks(tasks);
       context.setShowLoader?.('Loading Tasks', false);
     })
     .catch((error) => {
       console.log(error);
       context.setShowLoader?.('Loading Tasks', false);
     })
  
  }, []);


  function refreshTask(taskAlias:string) {
    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${taskAlias}`;
    context.setShowLoader?.('Refreshing Task', true);
    axios({
      "method": "GET",
      "url" : url
    })
    .then((response) => {
      let task:Task = response.data;
      updateTask(task);
      context.setShowLoader?.('Refreshing Task', false);
    })
    .catch((error) => {
      console.log(error);
      context.setShowLoader?.('Refreshing Task', false);
    })
  }

  function getTaskComponent(task:Task):JSX.Element | null {
    if (task.type === 1) {
      return <GlobalVials onTaskUpdate={updateTask} task={task} />
    }
    if (task.type === 2) {
      return <WorldBoard refreshTask={refreshTask} onTaskUpdate={updateTask} task={task} />
    }
    return null;
  } 

  return (
    <div className="world-page fade-in">
      <div className='user-balance'>
      <ResourceBalance balances={context.balances} />
      </div>
        <ul className='world-tasks'>
           {tasks.map((task:Task) => 
           <li key={'task-' + task.id}>{getTaskComponent(task)}</li>
           )} 
        </ul>
    </div>
  )
}

export default WorldPage;