import axios from 'axios';
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { AppContext } from '../../AppContext';
import IFigureDetail from '../../interfaces/FigureDetail';
import RewardToken from '../../interfaces/RewardToken';
import Token from '../../interfaces/Token';
import Figure from '../Figure';
import './FigureDetail.scss';
import VisibilitySensor from 'react-visibility-sensor';
import { useInView } from 'react-intersection-observer';

interface IProps {
    figure:IFigureDetail;
    edition?:number;
    totalEditions?:number|string;
}

function getElementName(elementId:number):string {
    switch (elementId) {
      case 1:
        return 'Death';
      case 2:
        return 'Life';
      case 3:
        return 'Artifact (Fire)';
      case 4:
        return 'Humor (Water)';
      case 5:
        return 'Locus (Earth)';
      case 6:
        return 'Craft (Air)';
      case 7:
        return 'Memory (Past)';
      case 8:
        return 'Vision (Future)';
      
      }
      return 'Death';
  }

const FigureDetail = (props: IProps) => {

    const figure = props.figure;

    const edition = props.edition || props.figure.edition;

    const totalEditions = props.totalEditions || props.figure.totalEditions;

    return (

        <div className='figure-detail'>
            <span className='figure-desc'>{figure.name}. {figure.description}</span>
            <span className='figure-artist'>{figure.artist}</span>

            <div className='figure-bottom'>
                <div className={'figure-element element-' + figure.elementId}>{getElementName(figure.elementId)}</div>
                {edition && <div className='reward-token-edition'>Edition: {edition}/{totalEditions}</div>}
            </div>
        </div>

    )
}

export default FigureDetail;