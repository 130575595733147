import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';

import './ModalContainer.scss';
interface IProps {
    children: JSX.Element | JSX.Element[];
    className?: string;
}

const ModalContainer = (props: IProps) => {



    return (
        <div className={"modal-container " + props.className}>
            {props.children}
            <div className="border">
                <span className='border-top'></span>
                <span className='border-sides'></span>
                <span className='border-bottom'></span>
            </div>
        </div>
    )
}

export default ModalContainer;