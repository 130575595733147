import axios from 'axios';
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { AppContext } from '../../AppContext';
import IFigureDetail from '../../interfaces/FigureDetail';
import RewardToken from '../../interfaces/RewardToken';
import Token from '../../interfaces/Token';
import Figure from '../Figure';
import './FigureSelect.scss';
import VisibilitySensor from 'react-visibility-sensor';
import { useInView } from 'react-intersection-observer';
import FigureDetail from '../FigureDetail';
import FigureDisplay from '../FigureDisplay';

interface IProps {
    criteria:string;
    onSelect(tokenId:number):void;
    onClose():void;
}



const FigureSelect = (props: IProps) => {

    const context = React.useContext(AppContext);
    const session = context.userSession;

    const [searchResults, setSearchResults] = React.useState<IFigureDetail[]>([]);

    useEffect(() => {
        const handleEsc = (event:KeyboardEvent) => {
           if (event.key === 'Escape' && props.onClose) {
            props.onClose();
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
      }, []);

    React.useEffect(() => {
        if (context.auth) {
          context.setShowLoader?.(`Loading Figures`, true);
        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getFigures`;
        
  
        axios({
            "method": "GET",
            "params" : {criteria : props.criteria},
            "url": url,
            "headers": {
                "signedMessage": session?.signature as string,
                "originalMessage": session?.message as string,
                "address": session?.address as string
            }
        })
            .then((response) => {
                let figures: IFigureDetail[] = response.data;
                setSearchResults(figures);
                context.setShowLoader?.(`Loading Figures`, false);
            })
            .catch((error) => {
                console.log(error);
                context.setShowLoader?.(`Loading Figures`, false);
            })
    
    
          }
    
      }, [props.criteria]);
    
    
    return (

        <div className='figure-select'>
            <div className='figure-select-close'><button onClick={props.onClose}><svg viewBox="0 0 24 24">
<path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
</svg></button></div>
            <div className='figure-scroll-wrapper'>
                <ul>
                {searchResults.map((figure:IFigureDetail) => 
                    <li>
                        <div className={'figure-select-item element-' + figure.elementId } onClick={() => props.onSelect(figure.id)}>
                            <FigureDisplay token={figure} size={'half'} />
                            <FigureDetail  figure={figure} />
                        </div>
                    </li>
                )}
                </ul>
            </div>
            <div className="border">
            <span className='border-top'></span>
            <span className='border-sides'></span>
            <span className='border-bottom'></span>
        </div>
        </div>

    )
}

export default FigureSelect;